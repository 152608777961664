import { Union } from "./fable_modules/fable-library-js.4.17.0/Types.js";
import { union_type, int32_type, string_type } from "./fable_modules/fable-library-js.4.17.0/Reflection.js";
import { ofArray, singleton, tail, head, isEmpty } from "./fable_modules/fable-library-js.4.17.0/List.js";
import { Route_$007CInt$007C_$007C, Route_$007CQuery$007C_$007C } from "./fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { createElement } from "react";
import React from "react";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.2.7.0/React.fs.js";
import { RouterModule_urlSegments } from "./fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { createObj } from "./fable_modules/fable-library-js.4.17.0/Util.js";
import { singleton as singleton_1, delay, toList } from "./fable_modules/fable-library-js.4.17.0/Seq.js";
import { LoginHolder } from "./Login.js";
import { RegisterHolder } from "./Register.js";
import { UserAccount } from "./UserAccount.js";
import { Form } from "./Feedback.js";
import { PasswordRecovery } from "./PasswordRecovery.js";
import { Interop_reactApi as Interop_reactApi_1 } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { createRoot } from "react-dom/client";
import "../assets/style.scss";


export class Location extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Login", "Register", "UserAccount", "PwRecovery", "Feedback", "NotFound"];
    }
}

export function Location_$reflection() {
    return union_type("Account.Location", [], Location, () => [[], [], [], [["Item1", string_type], ["Item2", int32_type]], [], []]);
}

export function Location_FromUrl_7F866359(x) {
    let matchResult, reference, userId;
    if (!isEmpty(x)) {
        switch (head(x)) {
            case "sign-in": {
                if (isEmpty(tail(x))) {
                    matchResult = 0;
                }
                else {
                    matchResult = 5;
                }
                break;
            }
            case "sign-up": {
                if (isEmpty(tail(x))) {
                    matchResult = 1;
                }
                else {
                    matchResult = 5;
                }
                break;
            }
            case "account": {
                if (isEmpty(tail(x))) {
                    matchResult = 2;
                }
                else {
                    matchResult = 5;
                }
                break;
            }
            case "feedback": {
                if (isEmpty(tail(x))) {
                    matchResult = 3;
                }
                else {
                    matchResult = 5;
                }
                break;
            }
            case "password-recovery": {
                if (!isEmpty(tail(x))) {
                    if (!isEmpty(tail(tail(x)))) {
                        const activePatternResult = Route_$007CQuery$007C_$007C(head(tail(tail(x))));
                        if (activePatternResult != null) {
                            if (!isEmpty(activePatternResult)) {
                                if (head(activePatternResult)[0] === "uid") {
                                    const activePatternResult_1 = Route_$007CInt$007C_$007C(head(activePatternResult)[1]);
                                    if (activePatternResult_1 != null) {
                                        if (isEmpty(tail(activePatternResult))) {
                                            if (isEmpty(tail(tail(tail(x))))) {
                                                matchResult = 4;
                                                reference = head(tail(x));
                                                userId = activePatternResult_1;
                                            }
                                            else {
                                                matchResult = 5;
                                            }
                                        }
                                        else {
                                            matchResult = 5;
                                        }
                                    }
                                    else {
                                        matchResult = 5;
                                    }
                                }
                                else {
                                    matchResult = 5;
                                }
                            }
                            else {
                                matchResult = 5;
                            }
                        }
                        else {
                            matchResult = 5;
                        }
                    }
                    else {
                        matchResult = 5;
                    }
                }
                else {
                    matchResult = 5;
                }
                break;
            }
            default:
                matchResult = 5;
        }
    }
    else {
        matchResult = 5;
    }
    switch (matchResult) {
        case 0:
            return new Location(0, []);
        case 1:
            return new Location(1, []);
        case 2:
            return new Location(2, []);
        case 3:
            return new Location(4, []);
        case 4:
            return new Location(3, [reference, userId]);
        default:
            return new Location(5, []);
    }
}

function notFound() {
    const children = singleton(createElement("span", {
        children: ["404 - Fant ikke siden"],
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function Account() {
    let elems;
    const location = useFeliz_React__React_useState_Static_1505(Location_FromUrl_7F866359(RouterModule_urlSegments(window.location.pathname + window.location.search, 2)))[0];
    const props_1 = [["clientId", "768029527019-r3c69btl1u386u0d709ahcl830e4v67k.apps.googleusercontent.com"], ["children", singleton(createElement("div", createObj(ofArray([["style", {
        marginTop: 52,
    }], (elems = toList(delay(() => ((location.tag === 0) ? singleton_1(LoginHolder()) : ((location.tag === 1) ? singleton_1(RegisterHolder()) : ((location.tag === 2) ? singleton_1(createElement(UserAccount, null)) : ((location.tag === 4) ? singleton_1(createElement(Form, null)) : ((location.tag === 3) ? singleton_1(createElement(PasswordRecovery, {
        reference: location.fields[0],
        userId: location.fields[1],
    })) : singleton_1(notFound())))))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))))]];
    return Interop_reactApi_1.createElement(GoogleOAuthProvider, createObj(props_1));
}

export const root = createRoot(document.getElementById("aksjeio-app"));

root.render(createElement(Account, null));

