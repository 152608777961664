import { Union, Record } from "./fable_modules/fable-library-js.4.17.0/Types.js";
import { obj_type, tuple_type, union_type, record_type, bool_type, option_type, string_type } from "./fable_modules/fable-library-js.4.17.0/Reflection.js";
import { GenericResponse_$reflection } from "./Types.js";
import { FetchError, FetchError_$reflection } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library-js.4.17.0/Result.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedEncoderCached_437914C6, Auto_generateBoxedEncoder_437914C6 } from "./fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { Auto_generateBoxedDecoderCached_Z6670B51, Auto_generateBoxedDecoder_Z6670B51 } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { unwrap, map, defaultArg, some } from "./fable_modules/fable-library-js.4.17.0/Option.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { cons, ofArray, empty, singleton } from "./fable_modules/fable-library-js.4.17.0/List.js";
import { keyValueList } from "./fable_modules/fable-library-js.4.17.0/MapUtil.js";
import { toString } from "./fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { createObj, uncurry2 } from "./fable_modules/fable-library-js.4.17.0/Util.js";
import { createElement } from "react";
import React from "react";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";
import { MuiHelpers_createElement } from "./Feliz.MaterialUI/Mui.js";
import { AccordionActions, Button, AccordionDetails, Typography, AccordionSummary, Accordion, TextField } from "@mui/material";
import { Alert_snackError } from "./ViewHelpers.js";
import { useReact_useReducer_2B9E6EA0 } from "./fable_modules/Feliz.2.7.0/React.fs.js";
import { empty as empty_1, singleton as singleton_1, delay, toList } from "./fable_modules/fable-library-js.4.17.0/Seq.js";

class Model extends Record {
    constructor(OldPassword, NewPassword, RepeatPassword, ErrorMsg, Processing, Changed) {
        super();
        this.OldPassword = OldPassword;
        this.NewPassword = NewPassword;
        this.RepeatPassword = RepeatPassword;
        this.ErrorMsg = ErrorMsg;
        this.Processing = Processing;
        this.Changed = Changed;
    }
}

function Model_$reflection() {
    return record_type("PasswordForm.Model", [], Model, () => [["OldPassword", string_type], ["NewPassword", string_type], ["RepeatPassword", string_type], ["ErrorMsg", option_type(string_type)], ["Processing", bool_type], ["Changed", bool_type]]);
}

class Message extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UpdateOld", "UpdateNew", "UpdateRepeat", "Commit", "UpdateResponse", "DismissError"];
    }
}

function Message_$reflection() {
    return union_type("PasswordForm.Message", [], Message, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericResponse_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", GenericResponse_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], []]);
}

function init() {
    return new Model("", "", "", undefined, false, false);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1:
            return new Model(model.OldPassword, msg.fields[0], model.RepeatPassword, model.ErrorMsg, model.Processing, model.Changed);
        case 2:
            return new Model(model.OldPassword, model.NewPassword, msg.fields[0], model.ErrorMsg, model.Processing, model.Changed);
        case 3: {
            const validOld = model.OldPassword.length >= 0;
            const validNew = model.NewPassword.length >= 8;
            if ((validOld && validNew) && (model.NewPassword === model.RepeatPassword)) {
                return new Model(model.OldPassword, model.NewPassword, model.RepeatPassword, undefined, true, model.Changed);
            }
            else if (!validOld) {
                return new Model(model.OldPassword, model.NewPassword, model.RepeatPassword, `Gammelt passord er minst ${8} tegn.`, model.Processing, model.Changed);
            }
            else if (!validNew) {
                return new Model(model.OldPassword, model.NewPassword, model.RepeatPassword, `Nytt passord må være minst ${8} tegn.`, model.Processing, model.Changed);
            }
            else {
                return new Model(model.OldPassword, model.NewPassword, model.RepeatPassword, "Passord er ikke like.", model.Processing, model.Changed);
            }
        }
        case 4: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.OldPassword, model.NewPassword, model.RepeatPassword, "Kunne ikke oppdatere passord. Vennligst prøv igjen senere.", false, model.Changed);
            }
            else {
                const x_3 = res.fields[0];
                if (x_3.Result === "success") {
                    return new Model(model.OldPassword, model.NewPassword, model.RepeatPassword, model.ErrorMsg, model.Processing, true);
                }
                else {
                    return new Model(model.OldPassword, model.NewPassword, model.RepeatPassword, x_3.Message, false, model.Changed);
                }
            }
        }
        case 5:
            return new Model(model.OldPassword, model.NewPassword, model.RepeatPassword, undefined, model.Processing, model.Changed);
        default:
            return new Model(msg.fields[0], model.NewPassword, model.RepeatPassword, model.ErrorMsg, model.Processing, model.Changed);
    }
}

function commit(oldP, newP, repeatP, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        dispatch(new Message(3, []));
        if (((oldP.length >= 0) && (newP.length >= 8)) && (newP === repeatP)) {
            return ((body = Auto_generateBoxedEncoder_437914C6(tuple_type(string_type, string_type), undefined, undefined, undefined)([oldP, newP]), (decoder = Auto_generateBoxedDecoder_Z6670B51(GenericResponse_$reflection(), undefined, undefined), (data_1 = some(body), (caseStrategy_3 = undefined, (extra_3 = undefined, (() => {
                let properties_2;
                try {
                    const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, ["cors"])), (properties_2 = ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_3, extra_3)(data_1_1))]), properties_2), data_1), properties_2)));
                    const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/account/change-password", properties_3).then((_arg) => {
                        let response_1, decoder_1_1;
                        return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(GenericResponse_$reflection(), unwrap(caseStrategy_3), unwrap(extra_3))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                            let matchValue;
                            return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                    }))));
                    return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
                }
                catch (exn) {
                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
                }
            })())))))).then((_arg_2) => {
                dispatch(new Message(4, [_arg_2]));
                return Promise.resolve();
            });
        }
        else {
            return Promise.resolve();
        }
    }));
    pr_1.then();
}

function drawCompleted() {
    let elems;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 100 + "%",
    }], (elems = [createElement("i", {
        className: "fas fa-check-circle",
        style: {
            fontSize: 1.6 + "rem",
            color: "var(--buy)",
            marginRight: 5,
        },
    }), createElement("span", {
        style: {
            fontSize: 1.6 + "rem",
        },
        children: "Passord er endret.",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

function drawForm(model, dispatch) {
    const children = ofArray([MuiHelpers_createElement(TextField, [["style", {
        marginTop: 10,
        marginBottom: 10,
    }], ["type", "password"], ["onChange", (e) => {
        dispatch(new Message(0, [e.target.value]));
    }], ["variant", "outlined"], ["size", "small"], ["label", "Gammelt passord"], ["fullWidth", true]]), MuiHelpers_createElement(TextField, [["style", {
        marginTop: 10,
        marginBottom: 10,
    }], ["type", "password"], ["onChange", (e_1) => {
        dispatch(new Message(1, [e_1.target.value]));
    }], ["variant", "outlined"], ["size", "small"], ["label", "Nytt passord"], ["fullWidth", true]]), MuiHelpers_createElement(TextField, [["style", {
        marginTop: 10,
        marginBottom: 10,
    }], ["type", "password"], ["onChange", (e_2) => {
        dispatch(new Message(2, [e_2.target.value]));
    }], ["variant", "outlined"], ["size", "small"], ["label", "Gjenta nytt passord"], ["fullWidth", true]]), Alert_snackError(model.ErrorMsg, () => {
        dispatch(new Message(5, []));
    })]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function PasswordForm() {
    let elements_3, elements, children, children_1;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init());
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    return MuiHelpers_createElement(Accordion, [(elements_3 = [MuiHelpers_createElement(AccordionSummary, [["expandIcon", createElement("i", {
        className: "fas fa-chevron-down",
    })], (elements = [MuiHelpers_createElement(Typography, [["children", "Endre passord"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])]), (children = toList(delay(() => (!model_1.Changed ? singleton_1(drawForm(model_1, dispatch)) : singleton_1(drawCompleted())))), MuiHelpers_createElement(AccordionDetails, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = toList(delay(() => (!model_1.Changed ? singleton_1(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Endre passord"], ["onClick", (_arg) => {
        if (!model_1.Processing) {
            commit(model_1.OldPassword, model_1.NewPassword, model_1.RepeatPassword, dispatch);
        }
    }]])) : empty_1()))), MuiHelpers_createElement(AccordionActions, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_3))])]);
}

