import { Union, Record } from "./fable_modules/fable-library-js.4.17.0/Types.js";
import { obj_type, union_type, record_type, option_type, bool_type, string_type } from "./fable_modules/fable-library-js.4.17.0/Reflection.js";
import { RegisterRequest_$reflection, RegisterRequest, GenericResponse_$reflection } from "./Types.js";
import { FetchError, FetchError_$reflection } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library-js.4.17.0/Result.js";
import { isMatch } from "./fable_modules/fable-library-js.4.17.0/RegExp.js";
import { Alert_snackError, LoadingIndicator_loadingIndicatorSmall, validateEmail } from "./ViewHelpers.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedEncoderCached_437914C6, Auto_generateBoxedEncoder_437914C6 } from "./fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { Auto_generateBoxedDecoderCached_Z6670B51, Auto_generateBoxedDecoder_Z6670B51 } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { unwrap, map, defaultArg, some } from "./fable_modules/fable-library-js.4.17.0/Option.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { cons, ofArray, empty, singleton } from "./fable_modules/fable-library-js.4.17.0/List.js";
import { keyValueList } from "./fable_modules/fable-library-js.4.17.0/MapUtil.js";
import { toString } from "./fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { createObj, uncurry2 } from "./fable_modules/fable-library-js.4.17.0/Util.js";
import { createElement } from "react";
import React from "react";
import { MuiHelpers_createElement } from "./Feliz.MaterialUI/Mui.js";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";
import { empty as empty_1, singleton as singleton_1, delay, toList } from "./fable_modules/fable-library-js.4.17.0/Seq.js";
import { recaptchaContainer } from "./Recaptcha.js";
import { useReact_useReducer_2B9E6EA0 } from "./fable_modules/Feliz.2.7.0/React.fs.js";
import { AuthHolder } from "./Login.js";

class Model extends Record {
    constructor(Username, Email, Password, PasswordRepeat, AcceptTerms, ValidUsername, ValidEmail, ValidPassword, Recaptched, Processing, ErrorMsg, Successful) {
        super();
        this.Username = Username;
        this.Email = Email;
        this.Password = Password;
        this.PasswordRepeat = PasswordRepeat;
        this.AcceptTerms = AcceptTerms;
        this.ValidUsername = ValidUsername;
        this.ValidEmail = ValidEmail;
        this.ValidPassword = ValidPassword;
        this.Recaptched = Recaptched;
        this.Processing = Processing;
        this.ErrorMsg = ErrorMsg;
        this.Successful = Successful;
    }
}

function Model_$reflection() {
    return record_type("Register.Model", [], Model, () => [["Username", string_type], ["Email", string_type], ["Password", string_type], ["PasswordRepeat", string_type], ["AcceptTerms", bool_type], ["ValidUsername", bool_type], ["ValidEmail", bool_type], ["ValidPassword", bool_type], ["Recaptched", bool_type], ["Processing", bool_type], ["ErrorMsg", option_type(string_type)], ["Successful", bool_type]]);
}

class Message extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UpdateUsername", "UpdateEmail", "UpdatePassword", "UpdatePasswordRepeat", "ToggleAcceptTerms", "TryRegister", "SetRecaptched", "RecaptchaResponse", "RegisterResponse", "DismissError"];
    }
}

function Message_$reflection() {
    return union_type("Register.Message", [], Message, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [], [["Item", bool_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericResponse_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", GenericResponse_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericResponse_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", GenericResponse_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], []]);
}

function init() {
    return new Model("", "", "", "", false, true, true, true, false, false, undefined, false);
}

function validateCredentials(un, email, pw1, pw2) {
    const usernameLength = (un.length >= 3) && (un.length <= 16);
    const usernameChars = isMatch(/^[0-9A-Za-z]+$/gu, un);
    const email_1 = validateEmail(email);
    const pwLength = pw1.length >= 8;
    const pwsMatch = pw1 === pw2;
    if (usernameLength) {
        if (usernameChars) {
            if (email_1) {
                if (pwLength) {
                    if (pwsMatch) {
                        return new FSharpResult$2(0, [undefined]);
                    }
                    else {
                        return new FSharpResult$2(1, ["Passord er ikke like."]);
                    }
                }
                else {
                    return new FSharpResult$2(1, ["Passordet er for kort."]);
                }
            }
            else {
                return new FSharpResult$2(1, ["Ugyldig e-post adresse"]);
            }
        }
        else {
            return new FSharpResult$2(1, ["Brukernavn kan kun inneholder bokstaver og tall."]);
        }
    }
    else {
        return new FSharpResult$2(1, ["Brukernavn må være mellom 3 og 16 tegn langt."]);
    }
}

function update(model, msg) {
    switch (msg.tag) {
        case 1:
            return new Model(model.Username, msg.fields[0], model.Password, model.PasswordRepeat, model.AcceptTerms, model.ValidUsername, model.ValidEmail, model.ValidPassword, model.Recaptched, model.Processing, model.ErrorMsg, model.Successful);
        case 2:
            return new Model(model.Username, model.Email, msg.fields[0], model.PasswordRepeat, model.AcceptTerms, model.ValidUsername, model.ValidEmail, model.ValidPassword, model.Recaptched, model.Processing, model.ErrorMsg, model.Successful);
        case 3:
            return new Model(model.Username, model.Email, model.Password, msg.fields[0], model.AcceptTerms, model.ValidUsername, model.ValidEmail, model.ValidPassword, model.Recaptched, model.Processing, model.ErrorMsg, model.Successful);
        case 4:
            return new Model(model.Username, model.Email, model.Password, model.PasswordRepeat, msg.fields[0], model.ValidUsername, model.ValidEmail, model.ValidPassword, model.Recaptched, model.Processing, model.ErrorMsg, model.Successful);
        case 5: {
            const matchValue = validateCredentials(model.Username, model.Email, model.Password, model.PasswordRepeat);
            if (matchValue.tag === 1) {
                return new Model(model.Username, model.Email, model.Password, model.PasswordRepeat, model.AcceptTerms, model.ValidUsername, model.ValidEmail, model.ValidPassword, model.Recaptched, model.Processing, matchValue.fields[0], model.Successful);
            }
            else {
                return new Model(model.Username, model.Email, model.Password, model.PasswordRepeat, model.AcceptTerms, model.ValidUsername, model.ValidEmail, model.ValidPassword, model.Recaptched, true, undefined, model.Successful);
            }
        }
        case 8: {
            const result = msg.fields[0];
            if (result.tag === 0) {
                const resp = result.fields[0];
                if (resp.Result === "success") {
                    return new Model(model.Username, model.Email, model.Password, model.PasswordRepeat, model.AcceptTerms, model.ValidUsername, model.ValidEmail, model.ValidPassword, model.Recaptched, false, model.ErrorMsg, true);
                }
                else {
                    return new Model(model.Username, model.Email, model.Password, model.PasswordRepeat, model.AcceptTerms, model.ValidUsername, model.ValidEmail, model.ValidPassword, model.Recaptched, false, resp.Message, model.Successful);
                }
            }
            else {
                return new Model(model.Username, model.Email, model.Password, model.PasswordRepeat, model.AcceptTerms, model.ValidUsername, model.ValidEmail, model.ValidPassword, model.Recaptched, false, "Noe gikk galt, kunne ikke gjennomføre registrering. Vennligst prøv igjen senere.", model.Successful);
            }
        }
        case 6:
            return new Model(model.Username, model.Email, model.Password, model.PasswordRepeat, model.AcceptTerms, model.ValidUsername, model.ValidEmail, model.ValidPassword, msg.fields[0], model.Processing, model.ErrorMsg, model.Successful);
        case 7: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return model;
            }
            else if (res.fields[0].Result === "success") {
                return new Model(model.Username, model.Email, model.Password, model.PasswordRepeat, model.AcceptTerms, model.ValidUsername, model.ValidEmail, model.ValidPassword, true, model.Processing, model.ErrorMsg, model.Successful);
            }
            else {
                return model;
            }
        }
        case 9:
            return new Model(model.Username, model.Email, model.Password, model.PasswordRepeat, model.AcceptTerms, model.ValidUsername, model.ValidEmail, model.ValidPassword, model.Recaptched, model.Processing, undefined, model.Successful);
        default:
            return new Model(msg.fields[0], model.Email, model.Password, model.PasswordRepeat, model.AcceptTerms, model.ValidUsername, model.ValidEmail, model.ValidPassword, model.Recaptched, model.Processing, model.ErrorMsg, model.Successful);
    }
}

function validateRecaptcha(token, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        return ((body = Auto_generateBoxedEncoder_437914C6(string_type, undefined, undefined, undefined)(token), (decoder = Auto_generateBoxedDecoder_Z6670B51(GenericResponse_$reflection(), undefined, undefined), (data_1 = some(body), (caseStrategy_3 = undefined, (extra_3 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, ["cors"])), (properties_2 = ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_3, extra_3)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/recaptcha", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(GenericResponse_$reflection(), unwrap(caseStrategy_3), unwrap(extra_3))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(7, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function tryRegister(username, email, p1, p2, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        dispatch(new Message(5, []));
        const matchValue = validateCredentials(username, email, p1, p2);
        if (matchValue.tag === 0) {
            const req = new RegisterRequest(username, p1, email);
            return ((body = Auto_generateBoxedEncoder_437914C6(RegisterRequest_$reflection(), undefined, undefined, undefined)(req), (decoder = Auto_generateBoxedDecoder_Z6670B51(GenericResponse_$reflection(), undefined, undefined), (data_1 = some(body), (caseStrategy_3 = undefined, (extra_3 = undefined, (() => {
                let properties_2;
                try {
                    const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, ["cors"])), (properties_2 = ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_3, extra_3)(data_1_1))]), properties_2), data_1), properties_2)));
                    const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/sign-up", properties_3).then((_arg) => {
                        let response_1, decoder_1_1;
                        return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(GenericResponse_$reflection(), unwrap(caseStrategy_3), unwrap(extra_3))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                            let matchValue_1;
                            return Promise.resolve((matchValue_1 = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue_1.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue_1.fields[0]])])) : (new FSharpResult$2(0, [matchValue_1.fields[0]]))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                    }))));
                    return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
                }
                catch (exn) {
                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
                }
            })())))))).then((_arg_2) => {
                dispatch(new Message(8, [_arg_2]));
                return Promise.resolve();
            });
        }
        else {
            return Promise.resolve();
        }
    }));
    pr_1.then();
}

function registrationView(model, dispatch) {
    let elems_4, children, elems, elems_1, elems_3;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    }], (elems_4 = [createElement("h1", {
        style: {
            fontSize: 2 + "rem",
            color: "var(--text-main)",
            fontWeight: "bold",
        },
        children: "Registrer deg",
    }), MuiHelpers_createElement(TextField, [["autoFocus", true], ["id", "username-field"], ["variant", "outlined"], ["label", "Brukernavn"], ["autoComplete", "username"], ["onChange", (e) => {
        dispatch(new Message(0, [e.target.value]));
    }], ["onKeyDown", (e_1) => {
        if (e_1.code === "Enter") {
            window.document.getElementById("email-field").focus();
        }
    }], ["error", !model.ValidUsername], ["style", {
        marginTop: 20 + "px",
        minWidth: 250 + "px",
    }]]), MuiHelpers_createElement(TextField, [["id", "email-field"], ["variant", "outlined"], ["label", "E-post adresse"], ["type", "email"], ["autoComplete", "email"], ["onChange", (e_2) => {
        dispatch(new Message(1, [e_2.target.value]));
    }], ["onKeyDown", (e_3) => {
        if (e_3.code === "Enter") {
            window.document.getElementById("password1-field").focus();
        }
    }], ["error", !model.ValidEmail], ["style", {
        marginTop: 20 + "px",
        minWidth: 250 + "px",
    }]]), MuiHelpers_createElement(TextField, [["id", "password1-field"], ["variant", "outlined"], ["label", "Passord"], ["type", "password"], ["autoComplete", "new-password"], ["onChange", (e_4) => {
        dispatch(new Message(2, [e_4.target.value]));
    }], ["onKeyDown", (e_5) => {
        if (e_5.code === "Enter") {
            window.document.getElementById("password2-field").focus();
        }
    }], ["error", !model.ValidPassword], ["style", {
        marginTop: 20 + "px",
        minWidth: 250 + "px",
    }]]), MuiHelpers_createElement(TextField, [["id", "password2-field"], ["variant", "outlined"], ["label", "Gjenta passord"], ["type", "password"], ["onChange", (e_6) => {
        dispatch(new Message(3, [e_6.target.value]));
    }], ["error", !model.ValidPassword], ["style", {
        marginTop: 20 + "px",
        marginBottom: 20 + "px",
        minWidth: 250 + "px",
    }]]), MuiHelpers_createElement(FormControlLabel, [["label", (children = ofArray(["Jeg har lest og aksepterer ", createElement("a", {
        style: {
            textDecoration: "underline",
        },
        target: "_blank",
        href: "/terms-of-use",
        children: "brukervilkårene",
    })]), createElement("span", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))], ["control", MuiHelpers_createElement(Checkbox, [["style", {
        color: "var(--text-main)",
    }], ["checked", model.AcceptTerms], ["onChange", (e_7) => {
        dispatch(new Message(4, [e_7.target.checked]));
    }], ["color", "primary"]])]]), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        minWidth: 250 + "px",
        fontSize: 0.8 + "rem",
        color: "var(--text-main)",
    }], (elems = [createElement("span", {
        children: ["Har du allerede en bruker?"],
    }), createElement("a", {
        style: {
            textDecoration: "underline",
        },
        href: "/sign-in",
        children: "Logg inn",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["style", {
        marginTop: 15,
    }], (elems_1 = toList(delay(() => (model.AcceptTerms ? singleton_1(recaptchaContainer([["sitekey", "6Lfptv8mAAAAAOjwVLP08rOqUO0zDXpcQ4kvDCul"], ["onChange", (s) => {
        if (s == null) {
            dispatch(new Message(6, [false]));
        }
        else {
            validateRecaptcha(s, dispatch);
        }
    }], ["theme", "dark"], ["onExpired", () => {
        dispatch(new Message(6, [false]));
    }]])) : empty_1()))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["style", {
        minHeight: 60 + "px",
        marginTop: 20 + "px",
        minWidth: 250 + "px",
    }], (elems_3 = toList(delay(() => {
        let elems_2;
        return (model.AcceptTerms && model.Recaptched) ? singleton_1(createElement("button", createObj(ofArray([["className", "pill-button"], ["style", {
            minWidth: 250 + "px",
        }], ["onClick", (_arg_1) => {
            tryRegister(model.Username, model.Email, model.Password, model.PasswordRepeat, dispatch);
        }], (elems_2 = toList(delay(() => (model.Processing ? singleton_1(LoadingIndicator_loadingIndicatorSmall()) : singleton_1("Registrer deg")))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))) : empty_1();
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), Alert_snackError(model.ErrorMsg, () => {
        dispatch(new Message(9, []));
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
}

export function Register() {
    let elems, value_19;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init());
    const model_1 = patternInput[0];
    if (!model_1.Successful) {
        return registrationView(model_1, patternInput[1]);
    }
    else {
        return createElement("div", createObj(ofArray([["style", {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        }], (elems = [createElement("span", {
            style: {
                fontSize: 1.7 + "rem",
                marginBottom: 25,
                padding: 10 + "px",
            },
            children: "Brukerkonto opprettet!",
        }), createElement("br", {}), createElement("p", createObj(ofArray([["style", {
            maxWidth: 500,
            textAlign: "center",
        }], (value_19 = "Du kan nå logge inn med din nye bruker på Aksje.io. Du må bekrefte din e-post adresse før du kan publisere innhold til Aksje.io. En e-post med instruksjoner om hvordan du kan bekrefte din e-post adresse er sendt til e-post adressen du registrerte brukeren med.", ["children", value_19])]))), createElement("a", {
            style: {
                marginTop: 20,
            },
            className: "pill-button",
            href: "/sign-in",
            children: "Logg inn",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
    }
}

export function RegisterHolder() {
    return createElement(AuthHolder, {
        authComponent: createElement(Register, null),
    });
}

