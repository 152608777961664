import { Record, Union } from "./fable_modules/fable-library-js.4.17.0/Types.js";
import { anonRecord_type, obj_type, record_type, bool_type, union_type, option_type, string_type } from "./fable_modules/fable-library-js.4.17.0/Reflection.js";
import { AppleAuthRequest_$reflection, AppleAuthRequest, GenericResponse_$reflection, OAuthRegisterRequest_$reflection, OAuthRegisterRequest, OAuthAuthResponse_$reflection, LoginRequest_$reflection, LoginRequest, LoginResponse_$reflection } from "./Types.js";
import { FetchError, FetchError_$reflection } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library-js.4.17.0/Result.js";
import { toString, utcNow, op_Subtraction, parse, toUniversalTime } from "./fable_modules/fable-library-js.4.17.0/Date.js";
import { days } from "./fable_modules/fable-library-js.4.17.0/TimeSpan.js";
import { printf, toText } from "./fable_modules/fable-library-js.4.17.0/String.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedEncoderCached_437914C6, Auto_generateBoxedEncoder_437914C6 } from "./fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { Auto_generateBoxedDecoderCached_Z6670B51, Auto_generateBoxedDecoder_Z6670B51 } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { unwrap, map, defaultArg, some } from "./fable_modules/fable-library-js.4.17.0/Option.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { cons, ofArray, empty, singleton } from "./fable_modules/fable-library-js.4.17.0/List.js";
import { keyValueList } from "./fable_modules/fable-library-js.4.17.0/MapUtil.js";
import { toString as toString_1 } from "./fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { equals, createObj, uncurry2 } from "./fable_modules/fable-library-js.4.17.0/Util.js";
import { createElement } from "react";
import React from "react";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library-js.4.17.0/Seq.js";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";
import { useFeliz_React__React_useState_Static_1505, useReact_useReducer_2B9E6EA0 } from "./fable_modules/Feliz.2.7.0/React.fs.js";
import { MuiHelpers_createElement } from "./Feliz.MaterialUI/Mui.js";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { Alert_snackError, LoadingIndicator_loadingIndicatorSmall, tryFocusInput } from "./ViewHelpers.js";
import { PasswordRecoveryForm_PasswordRecoveryForm } from "./PasswordRecovery.js";
import { Interop_reactApi as Interop_reactApi_1 } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";
import react_apple_login from "react-apple-login";
import { GoogleLogin } from "@react-oauth/google";

class LoginMode extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Normal", "Banned"];
    }
}

function LoginMode_$reflection() {
    return union_type("Login.LoginMode", [], LoginMode, () => [[], [["Item1", option_type(string_type)], ["Item2", string_type]]]);
}

class Model extends Record {
    constructor(Username, Password, Persist, ErrorMsg, ValidUsername, ValidPassword, Processing, Recover, Mode) {
        super();
        this.Username = Username;
        this.Password = Password;
        this.Persist = Persist;
        this.ErrorMsg = ErrorMsg;
        this.ValidUsername = ValidUsername;
        this.ValidPassword = ValidPassword;
        this.Processing = Processing;
        this.Recover = Recover;
        this.Mode = Mode;
    }
}

function Model_$reflection() {
    return record_type("Login.Model", [], Model, () => [["Username", string_type], ["Password", string_type], ["Persist", bool_type], ["ErrorMsg", option_type(string_type)], ["ValidUsername", bool_type], ["ValidPassword", bool_type], ["Processing", bool_type], ["Recover", bool_type], ["Mode", LoginMode_$reflection()]]);
}

class Message extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UpdateUsername", "UpdatePassword", "TogglePersist", "TryLogin", "LoginResponse", "SetError", "DismissError", "ToggleRecover"];
    }
}

function Message_$reflection() {
    return union_type("Login.Message", [], Message, () => [[["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [LoginResponse_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", LoginResponse_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", string_type]], [], []]);
}

function init() {
    return new Model("", "", true, undefined, true, true, false, false, new LoginMode(0, []));
}

function update(model, msg) {
    let matchValue, x_4, numDays, arg;
    switch (msg.tag) {
        case 1: {
            const x_1 = msg.fields[0];
            return new Model(model.Username, x_1, model.Persist, model.ErrorMsg, model.ValidUsername, x_1.length > 0, model.Processing, model.Recover, model.Mode);
        }
        case 2:
            return new Model(model.Username, model.Password, msg.fields[0], model.ErrorMsg, model.ValidUsername, model.ValidPassword, model.Processing, model.Recover, model.Mode);
        case 3: {
            const validUsername = model.Username.length > 0;
            const validPassword = model.Password.length > 0;
            if (validUsername && validPassword) {
                return new Model(model.Username, model.Password, model.Persist, undefined, true, true, true, model.Recover, model.Mode);
            }
            else {
                return new Model(model.Username, model.Password, model.Persist, model.ErrorMsg, validUsername, validPassword, model.Processing, model.Recover, model.Mode);
            }
        }
        case 4: {
            const res = msg.fields[0];
            if (res.tag === 0) {
                const x_3 = res.fields[0];
                switch (x_3.Result) {
                    case "success": {
                        window.location.reload(true);
                        return model;
                    }
                    case "banned":
                        return new Model(model.Username, model.Password, model.Persist, model.ErrorMsg, model.ValidUsername, model.ValidPassword, false, model.Recover, new LoginMode(1, [(matchValue = x_3.BanUntil, (matchValue != null) ? ((x_4 = toUniversalTime(parse(matchValue)), (numDays = (days(op_Subtraction(x_4, utcNow())) | 0), (arg = toString(x_4, "dd/MM/yyyy"), toText(printf("Til %s (%i dager igjen)"))(arg)(numDays))))) : undefined), x_3.Message]));
                    default:
                        return new Model(model.Username, model.Password, model.Persist, x_3.Message, model.ValidUsername, model.ValidPassword, false, model.Recover, model.Mode);
                }
            }
            else {
                return new Model(model.Username, model.Password, model.Persist, "Kunne ikke logge deg inn. Vennligst prøv igjen senere.", model.ValidUsername, model.ValidPassword, false, model.Recover, model.Mode);
            }
        }
        case 5:
            return new Model(model.Username, model.Password, model.Persist, msg.fields[0], model.ValidUsername, model.ValidPassword, model.Processing, model.Recover, model.Mode);
        case 6:
            return new Model(model.Username, model.Password, model.Persist, undefined, model.ValidUsername, model.ValidPassword, model.Processing, model.Recover, model.Mode);
        case 7:
            return new Model(model.Username, model.Password, model.Persist, model.ErrorMsg, model.ValidUsername, model.ValidPassword, model.Processing, !model.Recover, model.Mode);
        default: {
            const x = msg.fields[0];
            return new Model(x, model.Password, model.Persist, model.ErrorMsg, x.length > 0, model.ValidPassword, model.Processing, model.Recover, model.Mode);
        }
    }
}

function tryLogin(username, password, persist, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        dispatch(new Message(3, []));
        if ((username.trim().length > 0) && (password.trim().length > 0)) {
            const req = new LoginRequest(username, password, persist);
            return ((body = Auto_generateBoxedEncoder_437914C6(LoginRequest_$reflection(), undefined, undefined, undefined)(req), (decoder = Auto_generateBoxedDecoder_Z6670B51(LoginResponse_$reflection(), undefined, undefined), (data_1 = some(body), (caseStrategy_3 = undefined, (extra_3 = undefined, (() => {
                let properties_2;
                try {
                    const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, ["cors"])), (properties_2 = ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString_1(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_3, extra_3)(data_1_1))]), properties_2), data_1), properties_2)));
                    const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/sign-in", properties_3).then((_arg) => {
                        let response_1, decoder_1_1;
                        return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(LoginResponse_$reflection(), unwrap(caseStrategy_3), unwrap(extra_3))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                            let matchValue;
                            return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                    }))));
                    return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
                }
                catch (exn) {
                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
                }
            })())))))).then((_arg_2) => {
                dispatch(new Message(4, [_arg_2]));
                return Promise.resolve();
            });
        }
        else {
            return Promise.resolve();
        }
    }));
    pr_1.then();
}

function drawBanned(until, banReason) {
    let elems_2;
    return createElement("div", createObj(ofArray([["style", {
        width: 100 + "%",
        maxWidth: 500,
        padding: 5,
        display: "flex",
        flexDirection: "column",
        gap: ((10 + "px ") + 0) + "px",
    }], (elems_2 = toList(delay(() => {
        let elems;
        return append(singleton_1(createElement("div", createObj(ofArray([["style", {
            padding: 5,
            textAlign: "center",
            borderRadius: 5,
            color: "var(--sell)",
            fontWeight: "bold",
        }], (elems = [createElement("span", {
            children: ["Denne brukeren er utestengt fra aksje.io."],
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let matchValue, u, elems_1;
            return append((matchValue = until, (matchValue != null) ? ((u = matchValue, singleton_1(createElement("div", createObj(ofArray([["style", {
                textAlign: "center",
                color: "var(--text-main)",
            }], (elems_1 = [createElement("span", {
                children: [u],
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))))) : (empty_1())), delay(() => singleton_1(createElement("p", {
                style: {
                    marginTop: 10,
                    textAlign: "center",
                },
                children: banReason,
            }))));
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function Login() {
    let elems_2;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init());
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    return createElement("form", createObj(ofArray([["autoComplete", "on"], ["action", "#"], ["style", {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    }], (elems_2 = toList(delay(() => append(singleton_1(MuiHelpers_createElement(TextField, [["autoFocus", true], ["id", "username-field"], ["variant", "outlined"], ["label", "Brukernavn"], ["autoComplete", "username"], ["onChange", (e) => {
        dispatch(new Message(0, [e.target.value]));
    }], ["disabled", !equals(model_1.Mode, new LoginMode(0, []))], ["onKeyDown", (e_1) => {
        if (e_1.code === "Enter") {
            e_1.preventDefault();
            tryFocusInput("password-field");
        }
    }], ["error", !model_1.ValidUsername], ["InputProps", createObj(toList(delay(() => (!equals(model_1.Mode, new LoginMode(0, [])) ? singleton_1(["style", {
        color: "var(--text-dim)",
    }]) : empty_1()))))], ["style", {
        marginTop: 20 + "px",
        minWidth: 250 + "px",
    }]])), delay(() => append(singleton_1(MuiHelpers_createElement(TextField, [["id", "password-field"], ["variant", "outlined"], ["label", "Passord"], ["disabled", !equals(model_1.Mode, new LoginMode(0, []))], ["type", "password"], ["onChange", (e_2) => {
        dispatch(new Message(1, [e_2.target.value]));
    }], ["onKeyDown", (e_3) => {
        if (e_3.code === "Enter") {
            e_3.preventDefault();
            tryLogin(model_1.Username, model_1.Password, model_1.Persist, dispatch);
        }
    }], ["error", !model_1.ValidPassword], ["InputProps", createObj(toList(delay(() => (!equals(model_1.Mode, new LoginMode(0, [])) ? singleton_1(["style", {
        color: "var(--text-dim)",
    }]) : empty_1()))))], ["style", {
        marginTop: 20 + "px",
        marginBottom: 20 + "px",
        minWidth: 250 + "px",
    }]])), delay(() => {
        let matchValue;
        return append((matchValue = model_1.Mode, (matchValue.tag === 1) ? singleton_1(drawBanned(matchValue.fields[0], matchValue.fields[1])) : append(singleton_1(MuiHelpers_createElement(FormControlLabel, [["label", "Hold meg innlogget"], ["control", MuiHelpers_createElement(Checkbox, [["style", {
            color: "var(--text-main)",
        }], ["checked", model_1.Persist], ["onChange", (e_4) => {
            dispatch(new Message(2, [e_4.target.checked]));
        }], ["color", "primary"]])]])), delay(() => {
            let elems;
            return append(singleton_1(createElement("button", createObj(ofArray([["type", "submit"], ["className", "pill-button"], ["style", {
                marginTop: 20,
                minWidth: 250,
            }], ["onClick", (e_5) => {
                e_5.preventDefault();
                tryLogin(model_1.Username, model_1.Password, model_1.Persist, dispatch);
            }], (elems = toList(delay(() => (model_1.Processing ? singleton_1(LoadingIndicator_loadingIndicatorSmall()) : singleton_1("Logg inn")))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
                let elems_1;
                return singleton_1(createElement("div", createObj(ofArray([["style", {
                    marginTop: 20,
                    minWidth: 250 + "px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    fontSize: 0.8 + "rem",
                }], (elems_1 = [createElement("a", {
                    href: "/sign-up",
                    children: "Registrer deg",
                }), createElement("a", {
                    onClick: (_arg) => {
                        dispatch(new Message(7, []));
                    },
                    children: "Glemt passord?",
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))));
            }));
        }))), delay(() => append(singleton_1(Alert_snackError(model_1.ErrorMsg, () => {
            dispatch(new Message(6, []));
        })), delay(() => (model_1.Recover ? singleton_1(createElement(PasswordRecoveryForm_PasswordRecoveryForm, {
            onClose: () => {
                dispatch(new Message(7, []));
            },
        })) : empty_1())))));
    })))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

export class OAuthTarget extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Google", "Apple"];
    }
}

export function OAuthTarget_$reflection() {
    return union_type("Login.OAuthTarget", [], OAuthTarget, () => [[["Item", OAuthAuthResponse_$reflection()]], [["Item", OAuthAuthResponse_$reflection()]]]);
}

export function OAuthRegister(oAuthRegisterInputProps) {
    let elems_2, children, elems_1;
    const target = oAuthRegisterInputProps.target;
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(true);
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setProcessing = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(undefined);
    const setErrMsg = patternInput_3[1];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(false);
    const terms = patternInput_4[0];
    const title = (target.tag === 1) ? "Apple" : "Google";
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        maxWidth: 515,
        gap: ((20 + "px ") + 0) + "px",
        padding: 10,
    }], (elems_2 = [createElement("h1", {
        style: {
            fontSize: 2 + "rem",
            color: "var(--text-main)",
            fontWeight: "bold",
        },
        children: `Registrer deg med ${title}-konto`,
    }), createElement("p", {
        children: `Denne ${title}-kontoen og e-post adressen er ikke registrert på eksisterende aksje.io bruker. Fyll inn et brukernavn i feltet under for å komme i gang.`,
    }), MuiHelpers_createElement(TextField, [["autoFocus", true], ["id", "username-field"], ["variant", "outlined"], ["label", "Brukernavn"], ["autoComplete", "username"], ["onChange", (e) => {
        const u = e.target.value;
        const trimmed_1 = u.trim();
        patternInput[1](trimmed_1);
        patternInput_1[1](trimmed_1.length > 0);
    }], ["error", !patternInput_1[0]], ["style", {
        minWidth: 250 + "px",
    }]]), createElement("span", {
        children: ["Kun brukernavnet er synlig for andre brukere på aksje.io."],
    }), MuiHelpers_createElement(TextField, [["id", "email-field"], ["variant", "outlined"], ["label", "E-post adresse"], ["type", "email"], ["disabled", true], ["value", (target.tag === 1) ? target.fields[0].Email : target.fields[0].Email], ["style", {
        minWidth: 250 + "px",
    }]]), MuiHelpers_createElement(FormControlLabel, [["label", (children = ofArray(["Jeg har lest og aksepterer ", createElement("a", {
        style: {
            textDecoration: "underline",
        },
        target: "_blank",
        href: "/terms-of-use",
        children: "brukervilkårene",
    })]), createElement("span", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))], ["control", MuiHelpers_createElement(Checkbox, [["style", {
        color: "var(--text-main)",
    }], ["checked", terms], ["onChange", (e_1) => {
        patternInput_4[1](e_1.target.checked);
    }], ["color", "primary"]])]]), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        justifyContent: "center",
        minHeight: 40,
    }], (elems_1 = toList(delay(() => {
        let elems;
        return terms ? singleton_1(createElement("button", createObj(ofArray([["type", "submit"], ["className", "pill-button"], ["style", {
            width: 250,
        }], ["onClick", (e_2) => {
            e_2.preventDefault();
            const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                let body, decoder, data_1, caseStrategy_3, extra_3;
                const trimmed = patternInput[0].trim();
                if (!((trimmed.length >= 3) && (trimmed.length <= 16))) {
                    setErrMsg("Brukernavnet må være mellom 3 og 16 tegn");
                    return Promise.resolve();
                }
                else {
                    setErrMsg(undefined);
                    setProcessing(true);
                    const req = new OAuthRegisterRequest((target.tag === 1) ? target.fields[0].Token : target.fields[0].Token, trimmed);
                    const requestPath = (target.tag === 1) ? "/api/apple-auth/register" : "/api/google-auth/register";
                    return ((body = Auto_generateBoxedEncoder_437914C6(OAuthRegisterRequest_$reflection(), undefined, undefined, undefined)(req), (decoder = Auto_generateBoxedDecoder_Z6670B51(GenericResponse_$reflection(), undefined, undefined), (data_1 = some(body), (caseStrategy_3 = undefined, (extra_3 = undefined, (() => {
                        let properties_2;
                        try {
                            const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, ["cors"])), (properties_2 = ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString_1(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_3, extra_3)(data_1_1))]), properties_2), data_1), properties_2)));
                            const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                                let response_1, decoder_1_1;
                                return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(GenericResponse_$reflection(), unwrap(caseStrategy_3), unwrap(extra_3))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                                    let matchValue;
                                    return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                                })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                            }))));
                            return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
                        }
                        catch (exn) {
                            return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
                        }
                    })())))))).then((_arg_2) => {
                        const res = _arg_2;
                        if (res.tag === 0) {
                            const x_1 = res.fields[0];
                            if (x_1.Result === "success") {
                                window.location.reload(true);
                                return Promise.resolve();
                            }
                            else {
                                setErrMsg(x_1.Message);
                                setProcessing(false);
                                return Promise.resolve();
                            }
                        }
                        else {
                            setErrMsg("Kunne ikke registrere bruker. Vennligst prøv igjen senere");
                            setProcessing(false);
                            return Promise.resolve();
                        }
                    });
                }
            }));
            pr_1.then();
        }], (elems = toList(delay(() => (patternInput_2[0] ? singleton_1(LoadingIndicator_loadingIndicatorSmall()) : singleton_1("Registrer deg")))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))) : empty_1();
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), Alert_snackError(patternInput_3[0], () => {
        setErrMsg(undefined);
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

export function AuthHolder(authHolderInputProps) {
    let elems_7;
    const authComponent = authHolderInputProps.authComponent;
    const patternInput = useFeliz_React__React_useState_Static_1505(undefined);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(undefined);
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(undefined);
    const setBanned = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(undefined);
    const setErrMsg = patternInput_3[1];
    const domain = window.location.host;
    return createElement("div", createObj(ofArray([["className", "login-form"], ["style", {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    }], (elems_7 = toList(delay(() => append(singleton_1(Alert_snackError(patternInput_3[0], () => {
        setErrMsg(undefined);
    })), delay(() => {
        let elems_6, elems_3, elems_2, elems_5, elems_4;
        const matchValue_5 = patternInput_2[0];
        if (matchValue_5 == null) {
            const matchValue_6 = patternInput[0];
            if (matchValue_6 == null) {
                const matchValue_7 = patternInput_1[0];
                if (matchValue_7 == null) {
                    return singleton_1(createElement("div", createObj(ofArray([["style", {
                        display: "flex",
                        flexDirection: "column",
                        gap: ((40 + "px ") + 0) + "px",
                    }], (elems_6 = [createElement("div", createObj(ofArray([["style", {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: 50,
                        gap: ((20 + "px ") + 0) + "px",
                    }], (elems_3 = [createElement("div", createObj(ofArray([["style", {
                        padding: 2,
                    }], (elems_2 = [Interop_reactApi_1.createElement(react_apple_login, {
                        clientId: "io.aksje.www",
                        redirectURI: `https://${domain}/apple-auth`,
                        usePopup: true,
                        callback: (res_2) => {
                            console.log(some("apple login callback"));
                            console.log(some(res_2));
                            const callback = res_2;
                            const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                let matchValue, body, decoder, data_1, caseStrategy_3, extra_3;
                                if (callback.error == null) {
                                    const req = new AppleAuthRequest(callback.authorization.code, callback.authorization.id_token, (matchValue = callback.user, (matchValue != null) ? matchValue.email : undefined));
                                    return ((body = Auto_generateBoxedEncoder_437914C6(AppleAuthRequest_$reflection(), undefined, undefined, undefined)(req), (decoder = Auto_generateBoxedDecoder_Z6670B51(OAuthAuthResponse_$reflection(), undefined, undefined), (data_1 = some(body), (caseStrategy_3 = undefined, (extra_3 = undefined, (() => {
                                        let properties_2;
                                        try {
                                            const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, ["cors"])), (properties_2 = ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString_1(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_3, extra_3)(data_1_1))]), properties_2), data_1), properties_2)));
                                            const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/apple-auth/authenticate", properties_3).then((_arg) => {
                                                let response_1, decoder_1_1;
                                                return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(OAuthAuthResponse_$reflection(), unwrap(caseStrategy_3), unwrap(extra_3))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                                                    let matchValue_1;
                                                    return Promise.resolve((matchValue_1 = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue_1.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue_1.fields[0]])])) : (new FSharpResult$2(0, [matchValue_1.fields[0]]))));
                                                })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                                            }))));
                                            return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
                                        }
                                        catch (exn) {
                                            return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
                                        }
                                    })())))))).then((_arg_2) => {
                                        let matchValue_2, x_1, numDays, arg_1;
                                        const res = _arg_2;
                                        if (res.tag === 0) {
                                            const r = res.fields[0];
                                            switch (r.Result.Result) {
                                                case "success": {
                                                    window.location.reload(true);
                                                    return Promise.resolve();
                                                }
                                                case "not-exist": {
                                                    patternInput_1[1](r);
                                                    return Promise.resolve();
                                                }
                                                case "banned": {
                                                    setBanned([(matchValue_2 = r.Result.BanUntil, (matchValue_2 != null) ? ((x_1 = toUniversalTime(parse(matchValue_2)), (numDays = (days(op_Subtraction(x_1, utcNow())) | 0), (arg_1 = toString(x_1, "dd/MM/yyyy"), toText(printf("Til %s (%i dager igjen)"))(arg_1)(numDays))))) : undefined), r.Result.Message]);
                                                    return Promise.resolve();
                                                }
                                                default: {
                                                    setErrMsg(r.Result.Message);
                                                    return Promise.resolve();
                                                }
                                            }
                                        }
                                        else {
                                            setErrMsg("Kunne ikke logge deg inn. Vennligst prøv igjen senere.");
                                            return Promise.resolve();
                                        }
                                    });
                                }
                                else {
                                    return Promise.resolve();
                                }
                            }));
                            pr_1.then();
                        },
                        scope: "email",
                        responseMode: "query",
                        render: (renderProps) => {
                            let elems_1, elems;
                            return createElement("button", createObj(ofArray([["onClick", (_arg_7) => {
                                renderProps.onClick();
                            }], ["style", {
                                backgroundColor: "#202124",
                                color: "#e8eaed",
                                fontSize: 15 + "px",
                                minWidth: 190,
                                maxWidth: 400,
                                borderRadius: 25,
                                height: 40,
                                cursor: "pointer",
                            }], (elems_1 = [createElement("div", createObj(ofArray([["style", {
                                gap: ((0 + "px ") + 5) + "px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-around",
                                padding: ((0 + "px ") + 5) + "px",
                            }], (elems = [createElement("i", {
                                style: {
                                    fontSize: 25 + "px",
                                },
                                className: "fab fa-apple px-2",
                            }), "Logg inn med Apple"], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
                        },
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), Interop_reactApi_1.createElement(GoogleLogin, {
                        size: "large",
                        shape: "pill",
                        theme: "filled_black",
                        onSuccess: (x_15) => {
                            const pr_3 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                let item_1, body_3, decoder_3, data_5, caseStrategy_12, extra_12;
                                return ((item_1 = {
                                    Token: x_15.credential,
                                }, (body_3 = Auto_generateBoxedEncoder_437914C6(anonRecord_type(["Token", string_type]), undefined, undefined, undefined)(item_1), (decoder_3 = Auto_generateBoxedDecoder_Z6670B51(OAuthAuthResponse_$reflection(), undefined, undefined), (data_5 = some(body_3), (caseStrategy_12 = undefined, (extra_12 = undefined, (() => {
                                    let properties_6;
                                    try {
                                        const properties_3_1 = Helper_withProperties(singleton(new Types_RequestProperties(3, ["cors"])), (properties_6 = ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_5, empty()), 0)])]), defaultArg(map((data_1_2) => cons(new Types_RequestProperties(2, [toString_1(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_12, extra_12)(data_1_2))]), properties_6), data_5), properties_6)));
                                        const pr_2 = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/google-auth/authenticate", properties_3_1).then((_arg_3) => {
                                            let response_3, decoder_1_2;
                                            return ((response_3 = _arg_3, (decoder_1_2 = defaultArg(decoder_3, Auto_generateBoxedDecoderCached_Z6670B51(OAuthAuthResponse_$reflection(), unwrap(caseStrategy_12), unwrap(extra_12))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_3.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_3.text().then((_arg_4) => {
                                                let matchValue_3;
                                                return Promise.resolve((matchValue_3 = fromString(uncurry2(decoder_1_2), _arg_4), (matchValue_3.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue_3.fields[0]])])) : (new FSharpResult$2(0, [matchValue_3.fields[0]]))));
                                            })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_3])])))).then((_arg_1_2) => (Promise.resolve(_arg_1_2)))))))));
                                        }))));
                                        return pr_2.then(void 0, ((arg_3) => (new FSharpResult$2(1, [new FetchError(3, [arg_3])]))));
                                    }
                                    catch (exn_1) {
                                        return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn_1])])))));
                                    }
                                })()))))))).then((_arg_5) => {
                                    let matchValue_4, x_4, numDays_1, arg_4;
                                    const res_1 = _arg_5;
                                    if (res_1.tag === 0) {
                                        const r_1 = res_1.fields[0];
                                        switch (r_1.Result.Result) {
                                            case "success": {
                                                window.location.reload(true);
                                                return Promise.resolve();
                                            }
                                            case "not-exist": {
                                                patternInput[1](r_1);
                                                return Promise.resolve();
                                            }
                                            case "banned": {
                                                setBanned([(matchValue_4 = r_1.Result.BanUntil, (matchValue_4 != null) ? ((x_4 = toUniversalTime(parse(matchValue_4)), (numDays_1 = (days(op_Subtraction(x_4, utcNow())) | 0), (arg_4 = toString(x_4, "dd/MM/yyyy"), toText(printf("Til %s (%i dager igjen)"))(arg_4)(numDays_1))))) : undefined), r_1.Result.Message]);
                                                return Promise.resolve();
                                            }
                                            default: {
                                                setErrMsg(r_1.Result.Message);
                                                return Promise.resolve();
                                            }
                                        }
                                    }
                                    else {
                                        setErrMsg("Kunne ikke logge deg inn. Vennligst prøv igjen senere.");
                                        return Promise.resolve();
                                    }
                                });
                            }));
                            pr_3.then();
                        },
                        onError: (x_17) => {
                            console.log(some(`onError: ${x_17}`));
                        },
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("div", createObj(ofArray([["style", {
                        display: "flex",
                        justifyContent: "space-around",
                        gap: ((5 + "px ") + 5) + "px",
                        alignItems: "center",
                        width: 100 + "%",
                        maxWidth: 350,
                    }], (elems_5 = [createElement("div", {
                        style: {
                            flexGrow: 2,
                            height: 2,
                            backgroundColor: "var(--text-main)",
                        },
                    }), createElement("div", createObj(ofArray([["style", {
                        flexGrow: 1,
                        textAlign: "center",
                    }], (elems_4 = [createElement("span", {
                        children: "eller",
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", {
                        style: {
                            flexGrow: 2,
                            height: 2,
                            backgroundColor: "var(--text-main)",
                        },
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))), authComponent], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))));
                }
                else {
                    const arf = matchValue_7;
                    return singleton_1(createElement(OAuthRegister, {
                        target: new OAuthTarget(1, [arf]),
                    }));
                }
            }
            else {
                const grf = matchValue_6;
                return singleton_1(createElement(OAuthRegister, {
                    target: new OAuthTarget(0, [grf]),
                }));
            }
        }
        else {
            return singleton_1(drawBanned(matchValue_5[0], matchValue_5[1]));
        }
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])));
}

export function LoginHolder() {
    return createElement(AuthHolder, {
        authComponent: createElement(Login, null),
    });
}

