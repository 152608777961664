import { Union, Record } from "./fable_modules/fable-library-js.4.17.0/Types.js";
import { obj_type, union_type, record_type, bool_type, option_type, string_type } from "./fable_modules/fable-library-js.4.17.0/Reflection.js";
import { AccountInfo_$reflection, GenericResponse_$reflection } from "./Types.js";
import { FetchError, FetchError_$reflection } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library-js.4.17.0/Result.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedEncoderCached_437914C6, Auto_generateBoxedEncoder_437914C6 } from "./fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { Auto_generateBoxedDecoderCached_Z6670B51, Auto_generateBoxedDecoder_Z6670B51 } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { unwrap, map, defaultArg, some } from "./fable_modules/fable-library-js.4.17.0/Option.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { cons, ofArray, empty, singleton } from "./fable_modules/fable-library-js.4.17.0/List.js";
import { keyValueList } from "./fable_modules/fable-library-js.4.17.0/MapUtil.js";
import { toString } from "./fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { createObj, uncurry2 } from "./fable_modules/fable-library-js.4.17.0/Util.js";
import { createElement } from "react";
import React from "react";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0 } from "./fable_modules/Feliz.2.7.0/React.fs.js";
import { MuiHelpers_createElement } from "./Feliz.MaterialUI/Mui.js";
import { AccordionActions, Button, AccordionDetails, TextField, Typography, AccordionSummary, Accordion } from "@mui/material";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library-js.4.17.0/Seq.js";
import { LoadingIndicator_loadingIndicator, Alert_snackError } from "./ViewHelpers.js";
import { PasswordForm } from "./PasswordForm.js";
import { Sessions } from "./Sessions.js";

class DeleteForm_Model extends Record {
    constructor(Password, ErrorMsg, Processing) {
        super();
        this.Password = Password;
        this.ErrorMsg = ErrorMsg;
        this.Processing = Processing;
    }
}

function DeleteForm_Model_$reflection() {
    return record_type("UserAccount.DeleteForm.Model", [], DeleteForm_Model, () => [["Password", string_type], ["ErrorMsg", option_type(string_type)], ["Processing", bool_type]]);
}

class DeleteForm_Message extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UpdatePassword", "DismissError", "Commit", "CommitResponse"];
    }
}

function DeleteForm_Message_$reflection() {
    return union_type("UserAccount.DeleteForm.Message", [], DeleteForm_Message, () => [[["Item", string_type]], [], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericResponse_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", GenericResponse_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]]]);
}

function DeleteForm_init(emailRegistered) {
    return new DeleteForm_Model("", undefined, false);
}

function DeleteForm_update(model, msg) {
    switch (msg.tag) {
        case 1:
            return new DeleteForm_Model(model.Password, undefined, model.Processing);
        case 2:
            if (model.Password.length > 0) {
                return new DeleteForm_Model(model.Password, undefined, true);
            }
            else {
                return new DeleteForm_Model(model.Password, "Ugyldig passord", model.Processing);
            }
        case 3: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                const errMsg = "Kunne ikke slette bruker. Vennligst prøv igjen senere. Om problemet vedvarer ta kontakt med support@quasaras.com";
                return new DeleteForm_Model(model.Password, errMsg, false);
            }
            else {
                const x_1 = res.fields[0];
                if (x_1.Result === "success") {
                    window.location.reload();
                    return model;
                }
                else {
                    return new DeleteForm_Model(model.Password, x_1.Message, false);
                }
            }
        }
        default:
            return new DeleteForm_Model(msg.fields[0], model.ErrorMsg, model.Processing);
    }
}

function DeleteForm_commit(password, emailRegistered, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        dispatch(new DeleteForm_Message(2, []));
        return ((password.length > 0) ? true : !emailRegistered) ? (((body = Auto_generateBoxedEncoder_437914C6(string_type, undefined, undefined, undefined)(password), (decoder = Auto_generateBoxedDecoder_Z6670B51(GenericResponse_$reflection(), undefined, undefined), (data_1 = some(body), (caseStrategy_3 = undefined, (extra_3 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, ["cors"])), (properties_2 = ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_3, extra_3)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/account/delete", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(GenericResponse_$reflection(), unwrap(caseStrategy_3), unwrap(extra_3))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new DeleteForm_Message(3, [_arg_2]));
            return Promise.resolve();
        })) : (Promise.resolve());
    }));
    pr_1.then();
}

export function DeleteForm_DeleteForm(deleteForm_DeleteFormInputProps) {
    let elements_3, elements, children_2, children, children_3;
    const emailRegistered = deleteForm_DeleteFormInputProps.emailRegistered;
    const patternInput = useReact_useReducer_2B9E6EA0(DeleteForm_update, DeleteForm_init(emailRegistered));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    return MuiHelpers_createElement(Accordion, [(elements_3 = [MuiHelpers_createElement(AccordionSummary, [["expandIcon", createElement("i", {
        className: "fas fa-chevron-down",
    })], (elements = [MuiHelpers_createElement(Typography, [["children", "Slett bruker"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])]), (children_2 = singleton((children = toList(delay(() => append(singleton_1(createElement("p", {
        style: {
            fontSize: 0.9 + "rem",
        },
        children: "Brukerkontoen din vil bli permanent slettet. Den vil ikke kunne gjennopprettes.",
    })), delay(() => append(emailRegistered ? singleton_1(MuiHelpers_createElement(TextField, [["style", {
        marginTop: 10,
        marginBottom: 10,
    }], ["required", true], ["type", "password"], ["variant", "outlined"], ["label", "Passord"], ["onChange", (e) => {
        dispatch(new DeleteForm_Message(0, [e.target.value]));
    }], ["size", "small"], ["fullWidth", true]])) : empty_1(), delay(() => singleton_1(Alert_snackError(model_1.ErrorMsg, () => {
        dispatch(new DeleteForm_Message(1, []));
    })))))))), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))), MuiHelpers_createElement(AccordionDetails, [["children", Interop_reactApi.Children.toArray(Array.from(children_2))]])), (children_3 = singleton(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Slett konto"], ["onClick", (_arg_1) => {
        if (!model_1.Processing) {
            DeleteForm_commit(model_1.Password, emailRegistered, dispatch);
        }
    }]])), MuiHelpers_createElement(AccordionActions, [["children", Interop_reactApi.Children.toArray(Array.from(children_3))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_3))])]);
}

class Model extends Record {
    constructor(AccountInfo, EmailRequested, ErrorMsg) {
        super();
        this.AccountInfo = AccountInfo;
        this.EmailRequested = EmailRequested;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model_$reflection() {
    return record_type("UserAccount.Model", [], Model, () => [["AccountInfo", option_type(AccountInfo_$reflection())], ["EmailRequested", bool_type], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UserInfoResponse", "EmailRequestResponse", "DismissError"];
    }
}

function Message_$reflection() {
    return union_type("UserAccount.Message", [], Message, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [AccountInfo_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", AccountInfo_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericResponse_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", GenericResponse_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], []]);
}

function init() {
    return new Model(undefined, false, undefined);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1: {
            const res_1 = msg.fields[0];
            if (res_1.tag === 0) {
                const x_1 = res_1.fields[0];
                if (x_1.Result === "success") {
                    return new Model(model.AccountInfo, true, model.ErrorMsg);
                }
                else {
                    return new Model(model.AccountInfo, model.EmailRequested, x_1.Message);
                }
            }
            else {
                return new Model(model.AccountInfo, model.EmailRequested, "Kunne ikke forespørre ny e-post. Vennligst prøv igjen senere.");
            }
        }
        case 2:
            return new Model(model.AccountInfo, model.EmailRequested, undefined);
        default: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.AccountInfo, model.EmailRequested, "Kunne ikke laste kontoinformasjon. Vennligst prøv igjen senere.");
            }
            else {
                return new Model(res.fields[0], model.EmailRequested, model.ErrorMsg);
            }
        }
    }
}

function fetchAccountInfo(dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let decoder, data_1, caseStrategy_2, extra_2;
        return ((decoder = Auto_generateBoxedDecoder_Z6670B51(AccountInfo_$reflection(), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/account/info", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(AccountInfo_$reflection(), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })()))))).then((_arg_2) => {
            dispatch(new Message(0, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function requestEmail(dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        return ((body = Auto_generateBoxedEncoder_437914C6(string_type, undefined, undefined, undefined)(""), (decoder = Auto_generateBoxedDecoder_Z6670B51(GenericResponse_$reflection(), undefined, undefined), (data_1 = some(body), (caseStrategy_3 = undefined, (extra_3 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, ["cors"])), (properties_2 = ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_3, extra_3)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/sign-up/resend", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(GenericResponse_$reflection(), unwrap(caseStrategy_3), unwrap(extra_3))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(1, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function drawAccountInfo(accountInfo, emailRequested, dispatch) {
    let elems_4, elems_1, elems, elems_3;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        paddingBottom: 20 + "px",
        borderBottom: (((2 + "px ") + "solid") + " ") + "#aaa",
        marginBottom: 20 + "px",
    }], (elems_4 = [createElement("div", createObj(ofArray([["style", {
        marginRight: 20 + "px",
        display: "flex",
        alignItems: "center",
    }], (elems_1 = [createElement("div", createObj(ofArray([["className", "user-avatar"], ["style", {
        width: 100,
        height: 100,
    }], (elems = [createElement("img", {
        alt: accountInfo.Username,
        src: `/api/user/image/${loggedInUserId}`,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
    }], (elems_3 = toList(delay(() => {
        const row = (x) => createElement("span", {
            style: {
                fontSize: 0.9 + "rem",
            },
            children: x,
        });
        return append(singleton_1(createElement("span", {
            style: {
                fontSize: 1.6 + "rem",
                fontWeight: "bold",
            },
            children: accountInfo.Username,
        })), delay(() => append(singleton_1(row(accountInfo.Email)), delay(() => {
            let elems_2;
            return append(singleton_1(createElement("div", createObj(ofArray([["style", {
                display: "flex",
            }], (elems_2 = toList(delay(() => append(singleton_1(row(`E-post bekreftet: ${accountInfo.EmailVerified ? "Ja" : "Nei"}`)), delay(() => (emailRequested ? singleton_1(createElement("span", {
                style: {
                    fontSize: 0.9 + "rem",
                    marginLeft: 5,
                    textDecorationLine: "underline",
                },
                children: "E-post sendt",
            })) : (!accountInfo.EmailVerified ? singleton_1(createElement("span", {
                style: {
                    fontSize: 0.9 + "rem",
                    marginLeft: 15,
                    cursor: "pointer",
                    color: "#000000",
                    textDecorationLine: "underline",
                },
                onClick: (_arg) => {
                    requestEmail(dispatch);
                },
                children: "Send ny bekreftelses e-post",
            })) : empty_1())))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))), delay(() => append(singleton_1(row(`Medlem siden ${accountInfo.Joined}`)), delay(() => append(accountInfo.AppleRegistered ? singleton_1(row("Koblet mot Apple-konto")) : empty_1(), delay(() => (accountInfo.GoogleRegistered ? singleton_1(row("Koblet mot Google-konto")) : empty_1())))))));
        }))));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
}

function DrawSettings(x) {
    const children = toList(delay(() => append(x.EmailRegistered ? singleton_1(createElement(PasswordForm, null)) : empty_1(), delay(() => append(singleton_1(createElement(Sessions, null)), delay(() => singleton_1(createElement(DeleteForm_DeleteForm, {
        emailRegistered: x.EmailRegistered,
    }))))))));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function UserAccount() {
    let elems_1, elems;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init());
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    useReact_useEffectOnce_3A5B6456(() => {
        fetchAccountInfo(dispatch);
    });
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 10,
    }], (elems_1 = [createElement("div", createObj(ofArray([["style", {
        maxWidth: 600,
        marginTop: 20,
    }], (elems = toList(delay(() => append(singleton_1(Alert_snackError(model_1.ErrorMsg, () => {
        dispatch(new Message(2, []));
    })), delay(() => {
        const matchValue = model_1.AccountInfo;
        if (matchValue != null) {
            const accountInfo = matchValue;
            return append(singleton_1(drawAccountInfo(accountInfo, model_1.EmailRequested, dispatch)), delay(() => singleton_1(createElement(DrawSettings, accountInfo))));
        }
        else {
            return singleton_1(LoadingIndicator_loadingIndicator());
        }
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

