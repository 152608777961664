import { createElement } from "react";
import React from "react";
import { useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.2.7.0/React.fs.js";
import { uncurry2, createObj } from "./fable_modules/fable-library-js.4.17.0/Util.js";
import { singleton, append, delay, toList } from "./fable_modules/fable-library-js.4.17.0/Seq.js";
import { cons, empty, singleton as singleton_1, ofArray } from "./fable_modules/fable-library-js.4.17.0/List.js";
import { MuiHelpers_createElement } from "./Feliz.MaterialUI/Mui.js";
import { Button, TextField } from "@mui/material";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedEncoderCached_437914C6, Auto_generateBoxedEncoder_437914C6 } from "./fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { obj_type, tuple_type, string_type, int32_type } from "./fable_modules/fable-library-js.4.17.0/Reflection.js";
import { Auto_generateBoxedDecoderCached_Z6670B51, Auto_generateBoxedDecoder_Z6670B51 } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { GenericResponse_$reflection } from "./Types.js";
import { unwrap, map, defaultArg, some } from "./fable_modules/fable-library-js.4.17.0/Option.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { FetchError } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library-js.4.17.0/Result.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "./fable_modules/fable-library-js.4.17.0/MapUtil.js";
import { toString } from "./fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";
import { Alert_snackError } from "./ViewHelpers.js";

export function Form() {
    let elems_3, elems_2;
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const feedback = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(1);
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(undefined);
    const setErrMsg = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        justifyContent: "center",
        padding: 2 + "vw",
    }], (elems_3 = [createElement("div", createObj(ofArray([["style", {
        maxWidth: 600,
        width: 100 + "%",
        display: "flex",
        flexDirection: "column",
    }], (elems_2 = toList(delay(() => append(singleton(createElement("span", {
        style: {
            fontSize: 1.6 + "rem",
        },
        children: "Tilbakemelding",
    })), delay(() => {
        let value_24, elems_1;
        return !patternInput_3[0] ? append(singleton(createElement("span", createObj(ofArray([["style", {
            marginBottom: 10,
        }], (value_24 = "Har du ett forslag til funksjonalitet? Er det noe som ikke fungerer godt nok? Har du bare lyst å lette tankene? (relevant til Aksje.io plattformen da)", ["children", value_24])])))), delay(() => append(singleton(MuiHelpers_createElement(TextField, [["fullWidth", true], ["onChange", (e) => {
            patternInput[1](e.target.value);
        }], ["multiline", true], ["label", `${feedback.length}/${1000}`], ["error", feedback.length > 1000], ["variant", "outlined"], ["size", "small"]])), delay(() => {
            let elems;
            return append(singleton(createElement("div", createObj(ofArray([["style", {
                padding: 10,
                textAlign: "center",
            }], (elems = [MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Send inn"], ["onClick", (_arg_3) => {
                const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                    let body, decoder, data_1, caseStrategy_3, extra_3;
                    if (feedback.length === 0) {
                        setErrMsg("Vennligst oppgi tilbakemelding");
                        return Promise.resolve();
                    }
                    else if (feedback.length < 20) {
                        setErrMsg("Vennligst oppgi flere detaljer");
                        return Promise.resolve();
                    }
                    else if (feedback.length > 1000) {
                        setErrMsg("Maks lengde på tilbakemelding nådd");
                        return Promise.resolve();
                    }
                    else {
                        return ((body = Auto_generateBoxedEncoder_437914C6(tuple_type(int32_type, string_type), undefined, undefined, undefined)([patternInput_1[0], feedback]), (decoder = Auto_generateBoxedDecoder_Z6670B51(GenericResponse_$reflection(), undefined, undefined), (data_1 = some(body), (caseStrategy_3 = undefined, (extra_3 = undefined, (() => {
                            let properties_2;
                            try {
                                const properties_3 = Helper_withProperties(singleton_1(new Types_RequestProperties(3, ["cors"])), (properties_2 = ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_3, extra_3)(data_1_1))]), properties_2), data_1), properties_2)));
                                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/feedback", properties_3).then((_arg) => {
                                    let response_1, decoder_1_1;
                                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(GenericResponse_$reflection(), unwrap(caseStrategy_3), unwrap(extra_3))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                                        let matchValue;
                                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                                }))));
                                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
                            }
                            catch (exn) {
                                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
                            }
                        })())))))).then((_arg_2) => {
                            const res = _arg_2;
                            if (res.tag === 0) {
                                const x_1 = res.fields[0];
                                if (x_1.Result === "success") {
                                    patternInput_3[1](true);
                                    return Promise.resolve();
                                }
                                else {
                                    setErrMsg(x_1.Message);
                                    return Promise.resolve();
                                }
                            }
                            else {
                                setErrMsg("Kunne ikke sende tilbakemelding. Vennligst prøv igjen senere.");
                                return Promise.resolve();
                            }
                        });
                    }
                }));
                pr_1.then();
            }]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => singleton(Alert_snackError(patternInput_2[0], () => {
                setErrMsg(undefined);
            }))));
        })))) : singleton(createElement("div", createObj(ofArray([["style", {
            paddingTop: 50,
            textAlign: "center",
        }], (elems_1 = [createElement("span", {
            style: {
                fontSize: 1.3 + "rem",
            },
            children: "Din tilbakemelding er sendt til oss!",
        }), createElement("p", {
            style: {
                fontSize: 0.8 + "rem",
                marginTop: 10,
            },
            children: "Takk for at du deler din mening, det settes stor pris på.",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))));
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])));
}

