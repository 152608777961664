import { Record, Union } from "./fable_modules/fable-library-js.4.17.0/Types.js";
import { option_type, int32_type, obj_type, record_type, lambda_type, unit_type, bool_type, union_type, string_type } from "./fable_modules/fable-library-js.4.17.0/Reflection.js";
import { GenericResponse_$reflection } from "./Types.js";
import { FetchError, FetchError_$reflection } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library-js.4.17.0/Result.js";
import { Alert_snackError, LoadingIndicator_loadingIndicatorSmall, tryFocusInput, Dialog_dialogActions, Dialog_dialogContent, Dialog_dialogTitle, Dialog_Dialog, validateEmail } from "./ViewHelpers.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedEncoderCached_437914C6, Auto_generateBoxedEncoder_437914C6 } from "./fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { Auto_generateBoxedDecoderCached_Z6670B51, Auto_generateBoxedDecoder_Z6670B51 } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { unwrap, map, defaultArg, some } from "./fable_modules/fable-library-js.4.17.0/Option.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { cons, ofArray, empty, singleton } from "./fable_modules/fable-library-js.4.17.0/List.js";
import { keyValueList } from "./fable_modules/fable-library-js.4.17.0/MapUtil.js";
import { toString } from "./fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { equals, createObj, uncurry2 } from "./fable_modules/fable-library-js.4.17.0/Util.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { StyleCreator$1_$ctor, StyleImports_makeStyles_get } from "./Feliz.MaterialUI/Styles.js";
import { StyleCreator$1__create_4EAC9E1E } from "./Feliz.MaterialUI/Styles.js";
import { MuiHelpers_createElement } from "./Feliz.MaterialUI/Mui.js";
import { Paper, TextField } from "@mui/material";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library-js.4.17.0/Seq.js";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";
import { useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.2.7.0/React.fs.js";
import { recaptchaContainer } from "./Recaptcha.js";

class PasswordRecoveryForm_Step extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["EmailStep", "RecaptchaStep", "FailedStep", "CompletedStep"];
    }
}

function PasswordRecoveryForm_Step_$reflection() {
    return union_type("PasswordRecovery.PasswordRecoveryForm.Step", [], PasswordRecoveryForm_Step, () => [[], [], [["Item", string_type]], []]);
}

class PasswordRecoveryForm_Model extends Record {
    constructor(Email, CurrentStep, ValidEmail, Processing, OnClose) {
        super();
        this.Email = Email;
        this.CurrentStep = CurrentStep;
        this.ValidEmail = ValidEmail;
        this.Processing = Processing;
        this.OnClose = OnClose;
    }
}

function PasswordRecoveryForm_Model_$reflection() {
    return record_type("PasswordRecovery.PasswordRecoveryForm.Model", [], PasswordRecoveryForm_Model, () => [["Email", string_type], ["CurrentStep", PasswordRecoveryForm_Step_$reflection()], ["ValidEmail", bool_type], ["Processing", bool_type], ["OnClose", lambda_type(unit_type, unit_type)]]);
}

class PasswordRecoveryForm_Message extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UpdateEmail", "GotoStep", "InvalidateEmail", "Commit", "ResetResponse", "SetError", "DismissError"];
    }
}

function PasswordRecoveryForm_Message_$reflection() {
    return union_type("PasswordRecovery.PasswordRecoveryForm.Message", [], PasswordRecoveryForm_Message, () => [[["Item", string_type]], [["Item", PasswordRecoveryForm_Step_$reflection()]], [], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericResponse_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", GenericResponse_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", string_type]], []]);
}

function PasswordRecoveryForm_init(x) {
    return new PasswordRecoveryForm_Model("", new PasswordRecoveryForm_Step(0, []), true, false, x);
}

function PasswordRecoveryForm_update(model, msg) {
    switch (msg.tag) {
        case 1:
            return new PasswordRecoveryForm_Model(model.Email, msg.fields[0], model.ValidEmail, model.Processing, model.OnClose);
        case 2:
            return new PasswordRecoveryForm_Model(model.Email, model.CurrentStep, false, model.Processing, model.OnClose);
        case 3:
            if (validateEmail(model.Email)) {
                return new PasswordRecoveryForm_Model(model.Email, model.CurrentStep, true, true, model.OnClose);
            }
            else {
                return new PasswordRecoveryForm_Model(model.Email, model.CurrentStep, false, model.Processing, model.OnClose);
            }
        case 4: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new PasswordRecoveryForm_Model(model.Email, new PasswordRecoveryForm_Step(2, ["Kunne ikke forespørre tilbakestilling av passord. Vennligst prøv igjen senere."]), model.ValidEmail, false, model.OnClose);
            }
            else {
                const x_2 = res.fields[0];
                if (x_2.Result === "success") {
                    return new PasswordRecoveryForm_Model(model.Email, new PasswordRecoveryForm_Step(3, []), model.ValidEmail, model.Processing, model.OnClose);
                }
                else {
                    return new PasswordRecoveryForm_Model(model.Email, new PasswordRecoveryForm_Step(2, [x_2.Message]), model.ValidEmail, false, model.OnClose);
                }
            }
        }
        case 5:
            return new PasswordRecoveryForm_Model(model.Email, new PasswordRecoveryForm_Step(2, [msg.fields[0]]), model.ValidEmail, model.Processing, model.OnClose);
        case 6:
            return new PasswordRecoveryForm_Model(model.Email, new PasswordRecoveryForm_Step(0, []), model.ValidEmail, model.Processing, model.OnClose);
        default:
            return new PasswordRecoveryForm_Model(msg.fields[0], model.CurrentStep, model.ValidEmail, model.Processing, model.OnClose);
    }
}

function PasswordRecoveryForm_requestReset(email, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        return ((body = Auto_generateBoxedEncoder_437914C6(string_type, undefined, undefined, undefined)(email), (decoder = Auto_generateBoxedDecoder_Z6670B51(GenericResponse_$reflection(), undefined, undefined), (data_1 = some(body), (caseStrategy_3 = undefined, (extra_3 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, ["cors"])), (properties_2 = ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_3, extra_3)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/account/request-recovery", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(GenericResponse_$reflection(), unwrap(caseStrategy_3), unwrap(extra_3))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new PasswordRecoveryForm_Message(4, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function PasswordRecoveryForm_requested(model) {
    return createElement("p", {
        children: [`Tilbakestilling av passord ble forespurt for ${model.Email}. Om det eksisterer en brukerkonto med denne e-post adressen vil du motta en e-post med instruksjoner for hvordan du kan sette ett nytt passord.`],
    });
}

function PasswordRecoveryForm_failed(errMsg) {
    return createElement("p", {
        children: [errMsg],
    });
}

const PasswordRecoveryForm_useStyles = StyleImports_makeStyles_get((() => {
    let f1;
    const styles = StyleCreator$1_$ctor();
    f1 = ((theme) => ({
        paper: StyleCreator$1__create_4EAC9E1E(styles, singleton(["backgroundColor", "var(--bg-main)"])),
    }));
    return (arg) => {
        const o = f1(arg);
        return Object.assign({}, o);
    };
})());

function PasswordRecoveryForm_emailStep(model, dispatch) {
    const children = ofArray([createElement("p", {
        children: ["Forespør passordtilbakestilling ved å skrive inn e-post tilhørende brukerkonto"],
    }), MuiHelpers_createElement(TextField, toList(delay(() => append(singleton_1(["id", "recovery-email"]), delay(() => append(singleton_1(["defaultValue", model.Email]), delay(() => append(singleton_1(["error", !model.ValidEmail]), delay(() => append(!model.ValidEmail ? singleton_1(["helperText", "Invalid email"]) : empty_1(), delay(() => append(singleton_1(["onChange", (e) => {
        dispatch(new PasswordRecoveryForm_Message(0, [e.target.value]));
    }]), delay(() => append(singleton_1(["variant", "outlined"]), delay(() => append(singleton_1(["autoFocus", true]), delay(() => append(singleton_1(["label", "E-post adresse"]), delay(() => append(singleton_1(["type", "email"]), delay(() => singleton_1(["fullWidth", true]))))))))))))))))))))))]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

function PasswordRecoveryForm_requestRecaptcha(passwordRecoveryForm_requestRecaptchaInputProps) {
    let elems;
    const dispatch = passwordRecoveryForm_requestRecaptchaInputProps.dispatch;
    const model = passwordRecoveryForm_requestRecaptchaInputProps.model;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    return createElement("div", createObj(ofArray([["style", {
        minWidth: 300,
    }], (elems = [recaptchaContainer([["sitekey", "6Lfptv8mAAAAAOjwVLP08rOqUO0zDXpcQ4kvDCul"], ["onChange", (s) => {
        if (s == null) {
        }
        else {
            const dispatch_1 = dispatch;
            const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                let body, decoder, data_1, caseStrategy_3, extra_3;
                return ((body = Auto_generateBoxedEncoder_437914C6(string_type, undefined, undefined, undefined)(s), (decoder = Auto_generateBoxedDecoder_Z6670B51(GenericResponse_$reflection(), undefined, undefined), (data_1 = some(body), (caseStrategy_3 = undefined, (extra_3 = undefined, (() => {
                    let properties_2;
                    try {
                        const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, ["cors"])), (properties_2 = ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_3, extra_3)(data_1_1))]), properties_2), data_1), properties_2)));
                        const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/recaptcha", properties_3).then((_arg) => {
                            let response_1, decoder_1_1;
                            return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(GenericResponse_$reflection(), unwrap(caseStrategy_3), unwrap(extra_3))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                                let matchValue;
                                return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                            })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                        }))));
                        return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
                    }
                    catch (exn) {
                        return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
                    }
                })())))))).then((_arg_2) => {
                    const res = _arg_2;
                    if (res.tag === 1) {
                        dispatch_1(new PasswordRecoveryForm_Message(5, ["Kunne ikke starte tilbakestilling av passord. Vennligst prøv igjen senere."]));
                        return Promise.resolve();
                    }
                    else {
                        const x_1 = res.fields[0];
                        if (x_1.Result === "success") {
                            PasswordRecoveryForm_requestReset(model.Email, dispatch_1);
                            return Promise.resolve();
                        }
                        else {
                            dispatch_1(new PasswordRecoveryForm_Message(5, [x_1.Message]));
                            return Promise.resolve();
                        }
                    }
                });
            }));
            pr_1.then();
        }
    }], ["theme", "dark"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function PasswordRecoveryForm_PasswordRecoveryForm(passwordRecoveryForm_PasswordRecoveryFormInputProps) {
    const onClose = passwordRecoveryForm_PasswordRecoveryFormInputProps.onClose;
    const patternInput = useReact_useReducer_2B9E6EA0(PasswordRecoveryForm_update, PasswordRecoveryForm_init(onClose));
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    const s = PasswordRecoveryForm_useStyles();
    return createElement(Dialog_Dialog, {
        onClose: () => {
            model_1.OnClose();
        },
        children: ofArray([Dialog_dialogTitle("Glemt passord"), Dialog_dialogContent(toList(delay(() => {
            const matchValue = model_1.CurrentStep;
            return (matchValue.tag === 1) ? singleton_1(createElement(PasswordRecoveryForm_requestRecaptcha, {
                model: model_1,
                dispatch: dispatch,
            })) : ((matchValue.tag === 2) ? singleton_1(PasswordRecoveryForm_failed(matchValue.fields[0])) : ((matchValue.tag === 3) ? singleton_1(PasswordRecoveryForm_requested(model_1)) : singleton_1(PasswordRecoveryForm_emailStep(model_1, dispatch))));
        }))), Dialog_dialogActions(toList(delay(() => append(singleton_1(createElement("button", {
            className: "pill-button-small",
            onClick: (_arg_1) => {
                model_1.OnClose();
            },
            children: "Lukk",
        })), delay(() => (equals(model_1.CurrentStep, new PasswordRecoveryForm_Step(0, [])) ? singleton_1(createElement("button", {
            disabled: model_1.Email.length === 0,
            className: "pill-button-small",
            onClick: (_arg_2) => {
                if (validateEmail(model_1.Email)) {
                    dispatch(new PasswordRecoveryForm_Message(1, [new PasswordRecoveryForm_Step(1, [])]));
                }
                else {
                    dispatch(new PasswordRecoveryForm_Message(2, []));
                }
            },
            children: "Neste",
        })) : empty_1()))))))]),
    });
}

class Model extends Record {
    constructor(Reference, UserId, NewPassword, RepeatPassword, Completed, Processing, ErrorMsg) {
        super();
        this.Reference = Reference;
        this.UserId = (UserId | 0);
        this.NewPassword = NewPassword;
        this.RepeatPassword = RepeatPassword;
        this.Completed = Completed;
        this.Processing = Processing;
        this.ErrorMsg = ErrorMsg;
    }
}

function Model_$reflection() {
    return record_type("PasswordRecovery.Model", [], Model, () => [["Reference", string_type], ["UserId", int32_type], ["NewPassword", string_type], ["RepeatPassword", string_type], ["Completed", bool_type], ["Processing", bool_type], ["ErrorMsg", option_type(string_type)]]);
}

class Message extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UpdateNew", "UpdateRepeat", "Commit", "UpdateResponse", "DismissError"];
    }
}

function Message_$reflection() {
    return union_type("PasswordRecovery.Message", [], Message, () => [[["Item", string_type]], [["Item", string_type]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericResponse_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", GenericResponse_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], []]);
}

function init(x, y) {
    return new Model(x, y, "", "", false, false, undefined);
}

function update(model, msg) {
    switch (msg.tag) {
        case 1:
            return new Model(model.Reference, model.UserId, model.NewPassword, msg.fields[0], model.Completed, model.Processing, model.ErrorMsg);
        case 2: {
            const validNew = model.NewPassword.length >= 8;
            if (validNew && (model.NewPassword === model.RepeatPassword)) {
                return new Model(model.Reference, model.UserId, model.NewPassword, model.RepeatPassword, model.Completed, true, undefined);
            }
            else if (!validNew) {
                return new Model(model.Reference, model.UserId, model.NewPassword, model.RepeatPassword, model.Completed, model.Processing, `Nytt passord må være minst ${8} tegn langt.`);
            }
            else {
                return new Model(model.Reference, model.UserId, model.NewPassword, model.RepeatPassword, model.Completed, model.Processing, "Passord er ikke like.");
            }
        }
        case 3: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return new Model(model.Reference, model.UserId, model.NewPassword, model.RepeatPassword, model.Completed, false, "Kunne ikke sette nytt passord. Vennligst prøv igjen senere.");
            }
            else {
                const x_2 = res.fields[0];
                if (x_2.Result === "success") {
                    return new Model(model.Reference, model.UserId, model.NewPassword, model.RepeatPassword, true, model.Processing, model.ErrorMsg);
                }
                else {
                    return new Model(model.Reference, model.UserId, model.NewPassword, model.RepeatPassword, model.Completed, false, x_2.Message);
                }
            }
        }
        case 4:
            return new Model(model.Reference, model.UserId, model.NewPassword, model.RepeatPassword, model.Completed, model.Processing, undefined);
        default:
            return new Model(model.Reference, model.UserId, msg.fields[0], model.RepeatPassword, model.Completed, model.Processing, model.ErrorMsg);
    }
}

function trySetPassword(reference, userId, newPassword, repeat, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        dispatch(new Message(2, []));
        return ((newPassword.length >= 8) && (newPassword === repeat)) ? (((body = Auto_generateBoxedEncoder_437914C6(string_type, undefined, undefined, undefined)(newPassword), (decoder = Auto_generateBoxedDecoder_Z6670B51(GenericResponse_$reflection(), undefined, undefined), (data_1 = some(body), (caseStrategy_3 = undefined, (extra_3 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, ["cors"])), (properties_2 = ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_3, extra_3)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`/api/account/password-recovery/${reference}?uid=${userId}`, properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(GenericResponse_$reflection(), unwrap(caseStrategy_3), unwrap(extra_3))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Message(3, [_arg_2]));
            return Promise.resolve();
        })) : (Promise.resolve());
    }));
    pr_1.then();
}

function newPasswordForm(model, dispatch) {
    let elems_1, elems, elems_2;
    const xs_4 = ofArray([createElement("span", {
        style: {
            textAlign: "center",
            marginTop: 3 + "vh",
        },
        className: "title is-4",
        children: "Sett nytt passord",
    }), MuiHelpers_createElement(TextField, [["id", "password-field"], ["variant", "outlined"], ["label", "Nytt passord"], ["type", "password"], ["onChange", (e) => {
        dispatch(new Message(0, [e.target.value]));
    }], ["onKeyDown", (e_1) => {
        if (e_1.code === "Enter") {
            tryFocusInput("repeat-field");
        }
    }], ["style", {
        marginTop: 20 + "px",
        marginBottom: 20 + "px",
        minWidth: 250 + "px",
    }]]), MuiHelpers_createElement(TextField, [["id", "repeat-field"], ["variant", "outlined"], ["label", "Gjenta nytt passord"], ["type", "password"], ["onChange", (e_2) => {
        dispatch(new Message(1, [e_2.target.value]));
    }], ["style", {
        marginTop: 20 + "px",
        marginBottom: 20 + "px",
        minWidth: 250 + "px",
    }]]), createElement("div", createObj(ofArray([["style", {
        textAlign: "center",
    }], (elems_1 = [createElement("button", createObj(ofArray([["className", "pill-button"], ["style", {
        maxWidth: 250 + "px",
    }], ["onClick", (_arg) => {
        if (!model.Processing) {
            trySetPassword(model.Reference, model.UserId, model.NewPassword, model.RepeatPassword, dispatch);
        }
    }], (elems = toList(delay(() => (model.Processing ? singleton_1(LoadingIndicator_loadingIndicatorSmall()) : singleton_1("Sett nytt passord")))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["style", {
        marginTop: 20 + "px",
        minHeight: 80 + "px",
    }], (elems_2 = [Alert_snackError(model.ErrorMsg, () => {
        dispatch(new Message(4, []));
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))]);
    return react.createElement(react.Fragment, {}, ...xs_4);
}

function completedView() {
    let elems;
    return createElement("div", createObj(ofArray([["style", {
        textAlign: "center",
        marginTop: 3 + "vh",
    }], (elems = [createElement("span", {
        className: "title is-4",
        children: "Passord oppdatert",
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function PasswordRecovery(passwordRecoveryInputProps) {
    let elems_1, elements;
    const userId = passwordRecoveryInputProps.userId;
    const reference = passwordRecoveryInputProps.reference;
    const patternInput = useReact_useReducer_2B9E6EA0(update, init(reference, userId));
    const model_1 = patternInput[0];
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        justifyContent: "center",
        padding: 10,
    }], (elems_1 = [MuiHelpers_createElement(Paper, [["style", {
        padding: 2 + "vw",
        display: "flex",
        flexDirection: "column",
        marginTop: 5 + "vh",
        maxWidth: 500,
    }], (elements = toList(delay(() => (model_1.Completed ? singleton_1(completedView()) : singleton_1(newPasswordForm(model_1, patternInput[1]))))), ["children", Interop_reactApi.Children.toArray(Array.from(elements))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

