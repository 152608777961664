import { Record } from "./fable_modules/fable-library-js.4.17.0/Types.js";
import { option_type, record_type, bool_type, string_type } from "./fable_modules/fable-library-js.4.17.0/Reflection.js";

export class LoginRequest extends Record {
    constructor(Username, Password, Persist) {
        super();
        this.Username = Username;
        this.Password = Password;
        this.Persist = Persist;
    }
}

export function LoginRequest_$reflection() {
    return record_type("Types.LoginRequest", [], LoginRequest, () => [["Username", string_type], ["Password", string_type], ["Persist", bool_type]]);
}

export class LoginResponse extends Record {
    constructor(Result, Message, BanUntil) {
        super();
        this.Result = Result;
        this.Message = Message;
        this.BanUntil = BanUntil;
    }
}

export function LoginResponse_$reflection() {
    return record_type("Types.LoginResponse", [], LoginResponse, () => [["Result", string_type], ["Message", string_type], ["BanUntil", option_type(string_type)]]);
}

export class OAuthAuthResponse extends Record {
    constructor(Result, Email, Token) {
        super();
        this.Result = Result;
        this.Email = Email;
        this.Token = Token;
    }
}

export function OAuthAuthResponse_$reflection() {
    return record_type("Types.OAuthAuthResponse", [], OAuthAuthResponse, () => [["Result", LoginResponse_$reflection()], ["Email", string_type], ["Token", string_type]]);
}

export class AppleAuthRequest extends Record {
    constructor(Code, IdToken, Email) {
        super();
        this.Code = Code;
        this.IdToken = IdToken;
        this.Email = Email;
    }
}

export function AppleAuthRequest_$reflection() {
    return record_type("Types.AppleAuthRequest", [], AppleAuthRequest, () => [["Code", string_type], ["IdToken", string_type], ["Email", option_type(string_type)]]);
}

export class OAuthRegisterRequest extends Record {
    constructor(Token, Username) {
        super();
        this.Token = Token;
        this.Username = Username;
    }
}

export function OAuthRegisterRequest_$reflection() {
    return record_type("Types.OAuthRegisterRequest", [], OAuthRegisterRequest, () => [["Token", string_type], ["Username", string_type]]);
}

export class GenericResponse extends Record {
    constructor(Result, Message) {
        super();
        this.Result = Result;
        this.Message = Message;
    }
}

export function GenericResponse_$reflection() {
    return record_type("Types.GenericResponse", [], GenericResponse, () => [["Result", string_type], ["Message", string_type]]);
}

export class RegisterRequest extends Record {
    constructor(Username, Password, Email) {
        super();
        this.Username = Username;
        this.Password = Password;
        this.Email = Email;
    }
}

export function RegisterRequest_$reflection() {
    return record_type("Types.RegisterRequest", [], RegisterRequest, () => [["Username", string_type], ["Password", string_type], ["Email", string_type]]);
}

export class AccountInfo extends Record {
    constructor(Username, Email, EmailVerified, EmailRegistered, AppleRegistered, GoogleRegistered, Joined) {
        super();
        this.Username = Username;
        this.Email = Email;
        this.EmailVerified = EmailVerified;
        this.EmailRegistered = EmailRegistered;
        this.AppleRegistered = AppleRegistered;
        this.GoogleRegistered = GoogleRegistered;
        this.Joined = Joined;
    }
}

export function AccountInfo_$reflection() {
    return record_type("Types.AccountInfo", [], AccountInfo, () => [["Username", string_type], ["Email", string_type], ["EmailVerified", bool_type], ["EmailRegistered", bool_type], ["AppleRegistered", bool_type], ["GoogleRegistered", bool_type], ["Joined", string_type]]);
}

export class Session extends Record {
    constructor(Agent, IP, Since) {
        super();
        this.Agent = Agent;
        this.IP = IP;
        this.Since = Since;
    }
}

export function Session_$reflection() {
    return record_type("Types.Session", [], Session, () => [["Agent", string_type], ["IP", string_type], ["Since", string_type]]);
}

