import { createElement } from "react";
import React from "react";
import { useReact_useEffectOnce_3A5B6456, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.2.7.0/React.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedDecoderCached_Z6670B51, Auto_generateBoxedDecoder_Z6670B51 } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { GenericResponse_$reflection, Session_$reflection } from "./Types.js";
import { string_type, obj_type, array_type } from "./fable_modules/fable-library-js.4.17.0/Reflection.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { FetchError } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library-js.4.17.0/Result.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { singleton as singleton_1, cons, ofArray, empty } from "./fable_modules/fable-library-js.4.17.0/List.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "./fable_modules/fable-library-js.4.17.0/MapUtil.js";
import { some, unwrap, map, defaultArg } from "./fable_modules/fable-library-js.4.17.0/Option.js";
import { toString } from "./fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoder_437914C6, Auto_generateBoxedEncoderCached_437914C6 } from "./fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { createObj, uncurry2 } from "./fable_modules/fable-library-js.4.17.0/Util.js";
import { MuiHelpers_createElement } from "./Feliz.MaterialUI/Mui.js";
import { AccordionActions, Button, AccordionDetails, ListItemText, ListItem, List, Typography, AccordionSummary, Accordion } from "@mui/material";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";
import { append, singleton, delay, toList } from "./fable_modules/fable-library-js.4.17.0/Seq.js";
import { map as map_1 } from "./fable_modules/fable-library-js.4.17.0/Array.js";
import { LoadingIndicator_loadingIndicator } from "./ViewHelpers.js";

export function Sessions() {
    let elements_5, elements, children, children_1;
    const patternInput = useFeliz_React__React_useState_Static_1505(undefined);
    const sessions = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setConfirm = patternInput_1[1];
    useReact_useEffectOnce_3A5B6456(() => {
        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let decoder, data_1, caseStrategy_2, extra_2;
            return ((decoder = Auto_generateBoxedDecoder_Z6670B51(array_type(Session_$reflection()), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
                let properties_2;
                try {
                    const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                    const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/account/sessions", properties_3).then((_arg_1) => {
                        let response_1, decoder_1_1;
                        return ((response_1 = _arg_1, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(array_type(Session_$reflection()), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_2) => {
                            let matchValue;
                            return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_2), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                    }))));
                    return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
                }
                catch (exn) {
                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
                }
            })()))))).then((_arg_3) => {
                const res = _arg_3;
                if (res.tag === 1) {
                    return Promise.resolve();
                }
                else {
                    patternInput[1](res.fields[0]);
                    return Promise.resolve();
                }
            });
        }));
        pr_1.then();
    });
    return MuiHelpers_createElement(Accordion, [(elements_5 = [MuiHelpers_createElement(AccordionSummary, [["expandIcon", createElement("i", {
        className: "fas fa-chevron-down",
    })], (elements = [MuiHelpers_createElement(Typography, [["children", "Påloggede enheter"]])], ["children", Interop_reactApi.Children.toArray(Array.from(elements))])]), (children = toList(delay(() => {
        let elems_3, elements_2;
        if (sessions != null) {
            const x_4 = sessions;
            return singleton(createElement("div", createObj(ofArray([["style", {}], (elems_3 = [MuiHelpers_createElement(List, [["dense", true], (elements_2 = map_1((y) => {
                let elements_1;
                return MuiHelpers_createElement(ListItem, [(elements_1 = [MuiHelpers_createElement(ListItemText, [["primary", y.Agent], ["secondary", y.IP]])], ["children", Interop_reactApi.Children.toArray(Array.from(elements_1))])]);
            }, x_4), ["children", Interop_reactApi.Children.toArray(Array.from(elements_2))])])], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))));
        }
        else {
            return singleton(LoadingIndicator_loadingIndicator());
        }
    })), MuiHelpers_createElement(AccordionDetails, [["children", Interop_reactApi.Children.toArray(Array.from(children))]])), (children_1 = toList(delay(() => (patternInput_1[0] ? append(singleton(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Logg ut"], ["onClick", (_arg_7) => {
        const pr_3 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let body_2, decoder_3, data_5, caseStrategy_11, extra_11;
            return ((body_2 = Auto_generateBoxedEncoder_437914C6(string_type, undefined, undefined, undefined)(""), (decoder_3 = Auto_generateBoxedDecoder_Z6670B51(GenericResponse_$reflection(), undefined, undefined), (data_5 = some(body_2), (caseStrategy_11 = undefined, (extra_11 = undefined, (() => {
                let properties_6;
                try {
                    const properties_3_1 = Helper_withProperties(singleton_1(new Types_RequestProperties(3, ["cors"])), (properties_6 = ofArray([new Types_RequestProperties(0, ["DELETE"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_5, empty()), 0)])]), defaultArg(map((data_1_2) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_11, extra_11)(data_1_2))]), properties_6), data_5), properties_6)));
                    const pr_2 = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/account/sessions", properties_3_1).then((_arg_4) => {
                        let response_3, decoder_1_2;
                        return ((response_3 = _arg_4, (decoder_1_2 = defaultArg(decoder_3, Auto_generateBoxedDecoderCached_Z6670B51(GenericResponse_$reflection(), unwrap(caseStrategy_11), unwrap(extra_11))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_3.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_3.text().then((_arg_5) => {
                            let matchValue_1;
                            return Promise.resolve((matchValue_1 = fromString(uncurry2(decoder_1_2), _arg_5), (matchValue_1.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue_1.fields[0]])])) : (new FSharpResult$2(0, [matchValue_1.fields[0]]))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_3])])))).then((_arg_1_2) => (Promise.resolve(_arg_1_2)))))))));
                    }))));
                    return pr_2.then(void 0, ((arg_1) => (new FSharpResult$2(1, [new FetchError(3, [arg_1])]))));
                }
                catch (exn_1) {
                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn_1])])))));
                }
            })())))))).then((_arg_6) => {
                const res_1 = _arg_6;
                if (res_1.tag === 0) {
                    if (res_1.fields[0].Result === "success") {
                        window.location.reload(true);
                        return Promise.resolve();
                    }
                    else {
                        return Promise.resolve();
                    }
                }
                else {
                    return Promise.resolve();
                }
            });
        }));
        pr_3.then();
    }], ["style", {
        marginRight: 5,
    }]])), delay(() => singleton(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Avbryt"], ["onClick", (_arg_8) => {
        setConfirm(false);
    }]])))) : singleton(MuiHelpers_createElement(Button, [["size", "small"], ["variant", "outlined"], ["children", "Logg ut fra alle enheter"], ["onClick", (_arg_9) => {
        setConfirm(true);
    }]]))))), MuiHelpers_createElement(AccordionActions, [["children", Interop_reactApi.Children.toArray(Array.from(children_1))]]))], ["children", Interop_reactApi.Children.toArray(Array.from(elements_5))])]);
}

