import { split } from "./fable_modules/fable-library-js.4.17.0/String.js";
import { item } from "./fable_modules/fable-library-js.4.17.0/Array.js";
import { createElement } from "react";
import React from "react";
import { equals, createObj } from "./fable_modules/fable-library-js.4.17.0/Util.js";
import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";
import { ofArray } from "./fable_modules/fable-library-js.4.17.0/List.js";
import { defaultOf } from "./fable_modules/fable-library-js.4.17.0/Util.js";
import { React_createDisposable_3A5B6456, useReact_useEffect_Z5ECA432F, useReact_useRef_1505, useReact_useEffectOnce_3A5B6456 } from "./fable_modules/Feliz.2.7.0/React.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";

export function validateEmail(x) {
    const l = x.length > 5;
    const at = x.indexOf("@") >= 0;
    const dot = x.indexOf(".") >= 0;
    const splitted = split(x, ["@"], undefined, 0);
    if (splitted.length > 1) {
        if (((((l && at) && dot) && (item(1, splitted).length > 2)) && (item(0, splitted).length > 0)) && (item(1, splitted).indexOf(".") >= 0)) {
            return !(((x.indexOf(",") >= 0) ? true : (x.indexOf("\'") >= 0)) ? true : (x.indexOf("\\") >= 0));
        }
        else {
            return false;
        }
    }
    else {
        return false;
    }
}

export function Alert_alert(msg, onClose) {
    let elems;
    return createElement("div", createObj(ofArray([["className", "alert-error"], ["onClick", (_arg) => {
        onClose();
    }], (elems = [createElement("span", {
        children: [msg],
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function Alert_errorAlert(errMsg, dismiss) {
    if (errMsg != null) {
        return Alert_alert(errMsg, dismiss);
    }
    else {
        return defaultOf();
    }
}

export function Alert_SnackError(alert_SnackErrorInputProps) {
    let elems;
    const dismiss = alert_SnackErrorInputProps.dismiss;
    const errMsg = alert_SnackErrorInputProps.errMsg;
    useReact_useEffectOnce_3A5B6456(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => ((new Promise(resolve => setTimeout(resolve, 10000))).then(() => {
            dismiss();
            return Promise.resolve();
        }))));
        pr.then();
    });
    return createElement("div", createObj(ofArray([["className", "snackbar"], ["onClick", (_arg_2) => {
        dismiss();
    }], (elems = [Alert_alert(errMsg, dismiss)], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function Alert_snackError(errMsg, dismiss) {
    if (errMsg != null) {
        const errMsg_1 = errMsg;
        return createElement(Alert_SnackError, {
            errMsg: errMsg_1,
            dismiss: dismiss,
        });
    }
    else {
        return defaultOf();
    }
}

export function LoadingIndicator_loadingIndicatorSmall() {
    return createElement("div", {
        className: "loading",
        style: {
            width: 20,
            height: 20,
        },
    });
}

export function LoadingIndicator_loadingIndicator() {
    return createElement("div", {
        className: "loading",
        style: {
            width: 30,
            height: 30,
        },
    });
}

export function LoadingIndicator_loadingIndicatorLarge() {
    return createElement("div", {
        className: "loading",
        style: {
            width: 40,
            height: 40,
        },
    });
}

export function Dialog_dialogTitle(txt) {
    let elems;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        gap: 20 + "px ",
        alignItems: "center",
    }], (elems = [createElement("span", {
        style: {
            fontSize: 1.2 + "rem",
            fontWeight: 500,
        },
        children: txt,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function Dialog_dialogContent(children) {
    return createElement("div", {
        style: {
            display: "flex",
            flexDirection: "column",
            gap: ((20 + "px ") + 20) + "px",
        },
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function Dialog_dialogActions(children) {
    return createElement("div", {
        style: {
            display: "flex",
            gap: 20 + "px ",
            alignItems: "center",
            justifyContent: "flex-end",
        },
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function Dialog_Dialog(dialog_DialogInputProps) {
    let elems_1;
    const children = dialog_DialogInputProps.children;
    const onClose = dialog_DialogInputProps.onClose;
    const ref = useReact_useRef_1505(undefined);
    const closeEffect = (event) => {
        onClose();
    };
    useReact_useEffect_Z5ECA432F(() => {
        window.addEventListener("onclick", closeEffect);
        return React_createDisposable_3A5B6456(() => {
            window.removeEventListener("onclick", closeEffect);
        });
    });
    return createElement("div", createObj(ofArray([["ref", ref], ["className", "dialog-backdrop"], ["onClick", (event_1) => {
        if (equals(event_1.target, ref.current)) {
            window.removeEventListener("onclick", closeEffect);
            onClose();
        }
    }], (elems_1 = [createElement("div", {
        className: "dialog",
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function tryFocusInput(inputId) {
    try {
        window.document.getElementById(inputId).focus();
    }
    catch (matchValue) {
    }
}

